import React from 'react';
import { Box, Typography, Avatar, IconButton, Button } from '@mui/material';

import { stringAvatar } from '../../../../constants/functions';
import { withFirebase } from '../../../Firebase';
import CloseIcon from '@mui/icons-material/Close';
import RoomIcon from '@mui/icons-material/Room';

import { useTheme } from '@mui/material/styles';

const ToolTipContent = (props) => {
   const theme = useTheme();

   async function bookSpace(time) {
      var date = props.userData.date.ref;
      var sid = props.space.sid;
      var lid = props.space.lid;
      var form = {
         seats: 1,
         user: {
            uid: props.userData.user.uid,
            cid: props.userData.user.cid,
         },
         typeName: time,
      };

      let status = props.userData.status;
      if (
         props.userData.status.set === '-' &&
         props.userData.status.routine &&
         props.userData.status.routine === 'ito' &&
         !props.userData.status.overridden
      ) {
         status.set = 'ito';
      }
      try {
         await props.firebase.bookSpaceForUser(lid, date, sid, form, status);
      } catch (error) {
         console.warn(error, 'error');
      }
   }
   async function cancelSpaceBooking(booking) {
      let data = {
         user: {
            uid: props.userData.user.uid,
            cid: props.userData.user.cid,
         },
         space: {
            [booking.bid]: booking,
         },
         date: props.userData.date,
      };
      try {
         await props.firebase.cancelBookedSpaceForUser(data);
      } catch (error) {
         console.warn(error, 'error');
      }
   }
   async function showMap(sid, lid) {
      props.setSpaceMap({ sid, lid });
      props.setMap(true);
   }

   function offsiteSplitDayChecker(props) {
      if (
         !props.booking &&
         props.userData.status.set === 'split' &&
         props.userData.status.splitDay[props.time] === 'offsite' &&
         props.userData.status?.offsiteLocation?.[props.time]?.locationId ===
            props.space.lid
      ) {
         return true;
      } else if (
         props.booking &&
         props.booking[props.time] === null &&
         (props.userData.status.set === 'offsite' ||
            props.userData.status.splitDay[props.time] === 'offsite') &&
         (props.userData.status?.offsiteLocation?.[props.time]?.locationId ===
            props.space.lid ||
            props.userData.status?.offsiteLocation?.locationId === props.space.lid)
      ) {
         return true;
      } else {
         return false;
      }
   }

   const SplitBookButton = (props) => {
      if (
         (props.space.lid === props.userData.user.lid &&
            ((props.booking &&
               (props.userData.status.set === 'ito' ||
                  (props.userData.status.set === '-' &&
                     props.userData.status.routine === 'ito') ||
                  (props.userData.status.set === 'split' &&
                     props.userData.status.splitDay[props.time] === 'ito')) &&
               props.booking[props.time] === null) ||
               (!props.booked &&
                  props.userData.status.set === 'split' &&
                  props.userData.status.splitDay[props.time] === 'ito'))) ||
         offsiteSplitDayChecker(props)
      ) {
         return (
            <Button
               sx={{
                  mt: 1,
                  backgroundColor: 'white',
                  '&:hover': {
                     backgroundColor: 'primary.main',
                     color: 'whiteButtonHoverText.primary',
                  },
               }}
               variant="outlined"
               color="primary"
               fullWidth={true}
               onClick={() => bookSpace(props.time)}
            >
               Book - {props.time}
            </Button>
         );
      } else {
         return null;
      }
   };

   function showMainButton(booked, userData, space) {
      if (
         !booked &&
         ((space.lid === userData.user.lid &&
            (userData.status.set === 'ito' ||
               (userData.status.set === '-' && userData.status.routine === 'ito'))) ||
            (userData.status.set === 'offsite' &&
               userData.status?.offsiteLocation?.locationId === space.lid))
      ) {
         return true;
      } else {
         return false;
      }
   }

   return (
      <Box sx={{ minWidth: 250, p: 1 }}>
         <Box
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'space-between',
               mb: 2,
            }}
         >
            <Typography variant="body1" component="h6">
               {props.space.name}
            </Typography>
            {props.space.mapPath && (
               <IconButton
                  onClick={(e) => {
                     e.stopPropagation();
                     showMap(props.space.sid, props.space.lid);
                  }}
               >
                  <RoomIcon
                     fontSize="small"
                     sx={{ color: theme.palette.tableBody.main }}
                  />
               </IconButton>
            )}
         </Box>
         {!props.multi && props.booked && props.booking && props.booking.owner && (
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
               }}
            >
               {' '}
               <Box
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'flex-start',
                  }}
               >
                  <Avatar
                     {...stringAvatar(
                        `${props.booking.owner.name.firstName} ${props.booking.owner.name.lastName}`,
                     )}
                  />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                     {props.booking.owner.name.firstName}{' '}
                     {props.booking.owner.name.lastName}
                  </Typography>
               </Box>
               {props.booking.ownerId === props.userData.user.uid && (
                  <IconButton onClick={() => cancelSpaceBooking(props.booking)}>
                     <CloseIcon
                        fontSize="small"
                        sx={{ color: theme.palette.tableBody.main }}
                     />
                  </IconButton>
               )}
            </Box>
         )}
         {props.multi && props.booked && props.booking && (
            <React.Fragment>
               {props.booking.AM && (
                  <Box
                     sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                     }}
                  >
                     {props.booking.AM.owner ? (
                        <React.Fragment>
                           <Box
                              sx={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'flex-start',
                                 mb: 1,
                              }}
                           >
                              <Avatar
                                 {...stringAvatar(
                                    `${props.booking.AM.owner.name.firstName} ${props.booking.AM.owner.name.lastName}`,
                                 )}
                              />
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                 {props.booking.AM.owner.name.firstName}{' '}
                                 {props.booking.AM.owner.name.lastName} - AM
                              </Typography>
                           </Box>
                           {props.booking.AM.ownerId === props.userData.user.uid && (
                              <IconButton
                                 onClick={() => cancelSpaceBooking(props.booking.AM)}
                              >
                                 <CloseIcon
                                    fontSize="small"
                                    sx={{ color: theme.palette.tableBody.main }}
                                 />
                              </IconButton>
                           )}
                        </React.Fragment>
                     ) : (
                        <Typography variant="caption">Booked - AM</Typography>
                     )}
                  </Box>
               )}
               {props.booking.PM && (
                  <Box
                     sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 1,
                     }}
                  >
                     {props.booking.PM.owner ? (
                        <React.Fragment>
                           <Box
                              sx={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'flex-start',
                              }}
                           >
                              <Avatar
                                 {...stringAvatar(
                                    `${props.booking.PM.owner.name.firstName} ${props.booking.PM.owner.name.lastName}`,
                                 )}
                              />
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                 {props.booking.PM.owner.name.firstName}{' '}
                                 {props.booking.PM.owner.name.lastName} - PM
                              </Typography>
                           </Box>
                           {props.booking.PM.ownerId === props.userData.user.uid && (
                              <IconButton
                                 onClick={() => cancelSpaceBooking(props.booking.PM)}
                              >
                                 <CloseIcon
                                    fontSize="small"
                                    sx={{ color: theme.palette.tableBody.main }}
                                 />
                              </IconButton>
                           )}
                        </React.Fragment>
                     ) : (
                        <Typography variant="caption">Booked - PM</Typography>
                     )}
                  </Box>
               )}
            </React.Fragment>
         )}
         {props.booked && !props.booking && (
            <Typography variant="caption">Booked</Typography>
         )}
         {!props.booked &&
            props.space.lid === props.userData.user.lid &&
            props.userData.status.set !== 'ito' &&
            props.userData.status?.routine !== 'ito' &&
            props.userData.status.set === 'split' &&
            props.userData.status.splitDay.AM !== 'ito' &&
            props.userData.status.splitDay.PM !== 'ito' && (
               <Typography variant="caption">
                  Change your status to ITO to book a space
               </Typography>
            )}
         {showMainButton(props.booked, props.userData, props.space) && (
            <Button
               sx={{
                  mt: 1,
                  backgroundColor: 'white',
                  '&:hover': {
                     backgroundColor: 'primary.main',
                     color: 'whiteButtonHoverText.primary',
                  },
               }}
               variant="outlined"
               color="primary"
               fullWidth={true}
               onClick={() => bookSpace('All day')}
            >
               Book
            </Button>
         )}

         <SplitBookButton
            time={'AM'}
            userData={props.userData}
            booked={props.booked}
            booking={props.booking}
            space={props.space}
         />
         <SplitBookButton
            time={'PM'}
            userData={props.userData}
            booked={props.booked}
            booking={props.booking}
            space={props.space}
         />
      </Box>
   );
};

export default withFirebase(ToolTipContent);
