import React, { useEffect, useState } from 'react';
import {
   Box,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   Typography,
} from '@mui/material';

import { featureNames, convertToDate } from '../../../constants/functions';
import { withFirebase } from '../../Firebase';
import PastMonths from '../pastMonths';

const Billing = (props) => {
   const [currentUsage, setCurrentUsage] = useState(false);
   useEffect(() => {
      const loadCurrentBill = async () => {
         if (props.company && props.company.subscription.plan === 'premium') {
            let result = await props.firebase.companyCurrentBill(props.company.cid).get();
            if (result.exists) {
               setCurrentUsage(result.data());
            }
            return;
         }
      };
      loadCurrentBill();
   }, [props.company]);
   return (
      <Box>
         <Box
            component={Paper}
            sx={{ p: 3, m: 2, display: 'flex', justifyContent: 'space-evenly' }}
         >
            <Box>
               <Typography variant="body1">
                  Subscription: {props.company.subscription.plan}
               </Typography>
               <Typography variant="body1">
                  Start Date:{' '}
                  {props.company.subscription.planStartDate.toDate().toDateString()}
               </Typography>
            </Box>
            <Box>
               {props.company.currentBillTotal ? (
                  <React.Fragment>
                     <Typography>
                        Current: £{props.company.currentBillTotal.toFixed(2)}
                     </Typography>
                     <Typography>
                        Next bill date:
                        {props.company.nextBillingDate.toDate().toDateString()}
                     </Typography>
                  </React.Fragment>
               ) : (
                  <Typography>£0</Typography>
               )}
            </Box>
         </Box>
         {currentUsage && (
            <Box component={Paper} sx={{ p: 3, m: 2 }}>
               <Table size="small">
                  <TableHead>
                     <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Enabled features</TableCell>
                        <TableCell>No. of people</TableCell>
                        <TableCell>Cost</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {Object.entries(currentUsage).map(([key, value]) => {
                        if (key !== 'monthTotal') {
                           return (
                              <TableRow key={key}>
                                 <TableCell>{convertToDate(key)}</TableCell>
                                 <TableCell>
                                    {Object.entries(featureNames).map(
                                       ([featKey, featValue]) => {
                                          if (value[featKey]) {
                                             return (
                                                <Typography
                                                   key={featValue.name}
                                                   variant="caption"
                                                >
                                                   {' '}
                                                   {featValue.name},{' '}
                                                </Typography>
                                             );
                                          }
                                       },
                                    )}
                                 </TableCell>
                                 <TableCell>{value.people}</TableCell>
                                 <TableCell>£{value.dayTotal.toFixed(2)}</TableCell>
                              </TableRow>
                           );
                        }
                     })}
                     <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>Current Total</TableCell>
                        <TableCell>£{currentUsage.monthTotal.toFixed(2)} + VAT</TableCell>
                     </TableRow>
                  </TableBody>
               </Table>
            </Box>
         )}

         <Box sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
               Past Months
            </Typography>
            <PastMonths company={props.company} />
         </Box>
      </Box>
   );
};

export default withFirebase(Billing);
