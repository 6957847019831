import React from 'react';

// import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import enLocale from 'date-fns/locale/en-GB';

import TextField from '@material-ui/core/TextField';
import Collapse from '@mui/material/Collapse';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DateToday } from '../../../constants/functions';

const DateSelector = ({ date, setDate }) => {
   const [disabled, setDisabled] = React.useState(false);
   function handleDateChange(newDate) {
      let tod = new Date();
      let difference = newDate.getTime() - tod.getTime();
      let differenceInDays = Math.ceil(difference / (1000 * 3600 * 24));
      setDate(DateToday(differenceInDays));
   }

   function scroll(day) {
      let tod = new Date();
      let newDate = date.fullDate;
      newDate.setDate(newDate.getDate() + day);
      let difference = newDate.getTime() - tod.getTime();
      let differenceInDays = Math.ceil(difference / (1000 * 3600 * 24));
      if (differenceInDays > 0) {
         setDisabled(false);
      } else {
         setDisabled(true);
      }
      setDate(DateToday(differenceInDays));
   }
   const handleChange = () => {
      setCollapsed((prev) => !prev);
   };
   return (
      <div
         style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <IconButton
            disabled={disabled}
            onClick={() => {
               scroll(-1);
            }}
         >
            <ArrowBackIosOutlinedIcon />
         </IconButton>
         <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
            <DatePicker
               disablePast
               minDate={new Date()}
               value={date.fullDate}
               onChange={(e) => {
                  handleDateChange(e);
               }}
               renderInput={(params) => <TextField {...params} />}
            />
         </LocalizationProvider>
         <IconButton
            onClick={() => {
               scroll(1);
            }}
         >
            <ArrowForwardIosOutlinedIcon />
         </IconButton>
      </div>
   );
};

export default DateSelector;
