import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import AuthenticatedNav from './AuthenicatedNav';
import UnAuthenticatedNav from './UnAuthenticatedNav';

const drawerWidth = 240;

const openedMixin = (theme) => ({
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.complex,
   }),
   width: `${drawerWidth}px`,
   overflowX: 'hidden',
});

const closedMixin = (theme) => ({
   transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.complex,
   }),
   width: `0`,
   overflowX: 'hidden',
   [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
   },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
   ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
         ...openedMixin(theme),
         '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
         ...closedMixin(theme),
         '& .MuiDrawer-paper': closedMixin(theme),
      }),
   }),
);

const SideBar = ({ open, authUser }) => (
   <Drawer variant="permanent" open={open}>
      <Toolbar />
      <List disablePadding>
         {authUser ? <AuthenticatedNav authUser={authUser} /> : <UnAuthenticatedNav />}
      </List>
   </Drawer>
);

export default SideBar;
