import {
   Box,
   Button,
   Collapse,
   Divider,
   FormControl,
   FormControlLabel,
   FormHelperText,
   IconButton,
   Switch,
   TextField,
   Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Interweave } from 'interweave';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ImageIcon from '@mui/icons-material/Image';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
   saveCustomisations,
   getCustomisations,
   uploadConfirmationImage,
   deleteConfirmationImage,
   uploadBackgroundImage,
   deleteWelcomeBackgroundImage,
   loadImageFromStorage,
} from './functions';
import { DropzoneDialog } from 'material-ui-dropzone';
import ConfirmationImage from './confirmationImage';

const Customisations = ({ lid }) => {
   const [customisations, setCustomisations] = useState(null);
   const [saved, setSaved] = useState(true);
   const [error, setError] = useState(false);
   const [dialogOpen, setDialogOpen] = useState(false);
   const [expandPreview, setExpandPreview] = useState(false);
   const [welcomeBackgroundOpen, setWelcomeBackgroundOpen] = useState(false);
   const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);

   async function saveForm() {
      try {
         await saveCustomisations(customisations, lid);
         setSaved(true);
      } catch (error) {
         console.warn(error);
         setError(true);
      }
   }
   useEffect(() => {
      async function loadCustomisations() {
         try {
            const customisationsData = await getCustomisations(lid);
            setCustomisations(customisationsData);
         } catch (error) {
            console.warn(error);
            setError(true);
         }
      }
      loadCustomisations();
   }, [lid]);

   useEffect(() => {
      async function loadBackgroundImage() {
         if (
            customisations !== null &&
            customisations.welcomeBackgroundImagePath !== null
         ) {
            const url = await loadImageFromStorage(
               customisations.welcomeBackgroundImagePath,
            );
            setBackgroundImageUrl(url);
         } else {
            setBackgroundImageUrl(null);
         }
      }
      loadBackgroundImage();
   }, [customisations]);

   async function addText() {
      if (customisations.confirmationStatements === null) {
         setCustomisations({
            ...customisations,
            confirmationStatements: [{ type: 'text', content: '' }],
         });
      } else {
         const newStatements = [
            ...customisations.confirmationStatements,
            { type: 'text', content: '' },
         ];
         setCustomisations({ ...customisations, confirmationStatements: newStatements });
      }
      setSaved(false);
   }

   async function deleteStatement(index, type, path = null) {
      const newStatements = [...customisations.confirmationStatements];
      newStatements.splice(index, 1);
      if (type == 'image') {
         try {
            await deleteConfirmationImage(newStatements, lid, path);
            setCustomisations(await getCustomisations(lid));
         } catch (error) {
            alert(error);
         }
      } else {
         setCustomisations({ ...customisations, confirmationStatements: newStatements });
      }
   }

   async function reorderStatements(index, direction) {
      const newStatements = [...customisations.confirmationStatements];
      if (index + direction >= 0 && index + direction < newStatements.length) {
         const temp = newStatements[index];
         newStatements[index] = newStatements[index + direction];
         newStatements[index + direction] = temp;
         setCustomisations({ ...customisations, confirmationStatements: newStatements });
      }
   }
   const saveUpload = async (files) => {
      let path = `visitors/${lid}`;
      let statements = [];
      if (customisations.confirmationStatements != null) {
         statements = [...customisations.confirmationStatements];
      }
      statements.push({ type: 'image', path: `${path}/${files[0].name}` });

      try {
         await uploadConfirmationImage(files, statements, lid);
         setCustomisations(await getCustomisations(lid));
         setDialogOpen(false);
      } catch (error) {
         alert(error);
      }
   };
   const saveBackgroundimage = async (files) => {
      try {
         await uploadBackgroundImage(files, lid);
         setCustomisations(await getCustomisations(lid));
         setWelcomeBackgroundOpen(false);
      } catch (error) {
         alert(error);
      }
   };

   return (
      <Box>
         <Typography variant="h6">Customisations</Typography>
         {customisations !== null && (
            <Box sx={{ m: 2, p: 2, border: '1px solid #ccc' }}>
               <Typography variant="h6" paragraph>
                  Welcome page
               </Typography>
               <TextField
                  label="Welcome Title"
                  value={customisations.welcomeTitle}
                  size="small"
                  sx={{ mb: 2 }}
                  onChange={(e) => {
                     setCustomisations({
                        ...customisations,
                        welcomeTitle: e.target.value,
                     });
                     setSaved(false);
                  }}
               />
               <Box sx={{ border: '1px solid #ccc', p: 2 }}>
                  <Typography variant="caption" paragraph>
                     Welcome description
                  </Typography>
                  <ReactQuill
                     theme="snow"
                     value={customisations.welcomeMessage}
                     onChange={(e) => {
                        setCustomisations({ ...customisations, welcomeMessage: e });
                        setSaved(false);
                     }}
                  />
               </Box>
               <Box sx={{ mt: 2 }}>
                  {backgroundImageUrl !== null ? (
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: 'row',
                           justifyContent: 'space-between',
                           alignItems: 'center',
                        }}
                     >
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                           }}
                        >
                           <Typography variant="body1">
                              Welcome page hero background image.
                           </Typography>
                           <img
                              src={backgroundImageUrl}
                              alt="background"
                              style={{
                                 width: '250px',
                                 height: '100%',
                                 filter: 'grayscale(100%)',
                                 opacity: 0.25,
                              }}
                           />
                           <Typography variant="caption">
                              This image will form the entire background of the landlord
                              welcome page.
                           </Typography>
                        </Box>
                        <Button
                           onClick={() => {
                              deleteWelcomeBackgroundImage(
                                 lid,
                                 customisations.welcomeBackgroundImagePath,
                              );
                              setBackgroundImageUrl(null);
                           }}
                        >
                           Delete Image
                        </Button>
                     </Box>
                  ) : (
                     <Box>
                        <Typography variant="body1">
                           Welcome page background image
                        </Typography>
                        <DropzoneDialog
                           open={welcomeBackgroundOpen}
                           onSave={(files) => {
                              saveBackgroundimage(files);
                           }}
                           acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                           showPreviews={true}
                           maxFileSize={5000000}
                           filesLimit={1}
                           onClose={() => {
                              setWelcomeBackgroundOpen(false);
                              // setSaved(true);
                           }}
                        />
                        <Button onClick={() => setWelcomeBackgroundOpen(true)}>
                           Upload Image
                        </Button>
                     </Box>
                  )}
               </Box>
               <Divider sx={{ mt: 1, mb: 1 }} />
               <Box>
                  <Typography variant="h6">Car Registration</Typography>
                  <FormControl>
                     <FormControlLabel
                        label="Request Car Registration"
                        control={
                           <Switch
                              checked={customisations.carReg}
                              onChange={(e) => {
                                 setCustomisations({
                                    ...customisations,
                                    carReg: e.target.checked,
                                 });
                                 setSaved(false);
                              }}
                           />
                        }
                     />
                     <FormHelperText>
                        Add a check in stage to allow visitors to enter their car
                        registration.
                     </FormHelperText>
                  </FormControl>
               </Box>
               <Divider sx={{ mt: 1, mb: 1 }} />
               <Box>
                  <Typography variant="h6">Confirmation Statements</Typography>
                  <Typography variant="caption">
                     Add information, which visitors are required to confirm acceptance or
                     understanding, to the check in process. E.g. health and safety
                     policies, or information on fire exists, or what to do in an
                     emergency. You must have at least one text or image section for the
                     confirmation page to be active. Remove all sections to disable the
                     confirmation page.
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                     <TextField
                        label="Confirmation Page Title"
                        value={customisations.confirmationPageTitle}
                        size="small"
                        sx={{ mb: 2 }}
                        onChange={(e) => {
                           setCustomisations({
                              ...customisations,
                              confirmationPageTitle: e.target.value,
                           });
                           setSaved(false);
                        }}
                        helperText="Optional Title for the Confirmation Page"
                     />
                     <TextField
                        label="Confirmation statement wording"
                        value={customisations.confirmationStatementWording}
                        size="small"
                        sx={{ mb: 2 }}
                        onChange={(e) => {
                           setCustomisations({
                              ...customisations,
                              confirmationStatementWording: e.target.value,
                           });
                           setSaved(false);
                        }}
                        helperText="The final statment your visitors must confirm."
                     />

                     {customisations.confirmationStatements !== null &&
                        customisations.confirmationStatements.map((statement, index) => (
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 mb: 2,
                                 border: '1px solid #ccc',
                                 p: 2,
                              }}
                              key={index}
                           >
                              <Box>
                                 <IconButton
                                    size="small"
                                    onClick={() => reorderStatements(index, -1)}
                                 >
                                    <ArrowUpwardIcon />
                                 </IconButton>
                                 {index + 1}
                                 <IconButton
                                    size="small"
                                    onClick={() => reorderStatements(index, 1)}
                                 >
                                    <ArrowDownwardIcon />
                                 </IconButton>
                              </Box>
                              <Box sx={{ width: '80%' }}>
                                 {statement.type == 'text' ? (
                                    <ReactQuill
                                       theme="snow"
                                       value={statement.content}
                                       onChange={(e) => {
                                          const newStatements = [
                                             ...customisations.confirmationStatements,
                                          ];
                                          newStatements[index].content = e;
                                          setCustomisations({
                                             ...customisations,
                                             confirmationStatements: newStatements,
                                          });
                                          setSaved(false);
                                       }}
                                    />
                                 ) : (
                                    <ConfirmationImage path={statement.path} />
                                 )}
                              </Box>
                              <Box>
                                 <IconButton
                                    size="small"
                                    onClick={() =>
                                       deleteStatement(
                                          index,
                                          statement.type,
                                          statement.path,
                                       )
                                    }
                                 >
                                    <DeleteIcon />
                                 </IconButton>{' '}
                              </Box>
                           </Box>
                        ))}
                     <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-evenly' }}>
                        <DropzoneDialog
                           open={dialogOpen}
                           onSave={(files) => {
                              saveUpload(files);
                           }}
                           acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                           showPreviews={true}
                           maxFileSize={5000000}
                           filesLimit={1}
                           onClose={() => {
                              setDialogOpen(false);
                              setSaved(true);
                           }}
                        />
                        <Button
                           startIcon={<TextSnippetIcon />}
                           color="primary"
                           variant="outlined"
                           onClick={() => addText()}
                        >
                           Add Text
                        </Button>

                        <Button
                           startIcon={<ImageIcon />}
                           color="primary"
                           variant="outlined"
                           onClick={() => setDialogOpen(true)}
                        >
                           Add Image
                        </Button>
                        {customisations !== null &&
                           customisations.confirmationStatements !== null &&
                           customisations.confirmationStatements.length > 0 && (
                              <Button onClick={() => setExpandPreview(!expandPreview)}>
                                 {expandPreview ? 'Close Preview' : 'Preview'}
                              </Button>
                           )}
                     </Box>

                     {customisations !== null &&
                        customisations.confirmationStatements !== null &&
                        customisations.confirmationStatements.length > 0 && (
                           <Box>
                              <Collapse in={expandPreview}>
                                 <Box
                                    sx={{
                                       m: 1,
                                       maxHeight: '40vh',
                                       overflow: 'scroll',
                                       backgroundColor: '#fff',
                                       border: '1px solid #ccc',
                                       p: 1,
                                    }}
                                 >
                                    {customisations.confirmationStatements.map(
                                       (statement, index) => (
                                          <Box key={index}>
                                             {statement.type === 'image' ? (
                                                <ConfirmationImage
                                                   path={statement.path}
                                                />
                                             ) : (
                                                <Interweave content={statement.content} />
                                             )}
                                          </Box>
                                       ),
                                    )}
                                 </Box>
                              </Collapse>
                           </Box>
                        )}
                  </Box>
               </Box>
               <Divider sx={{ mt: 1, mb: 1 }} />
               <Box sx={{ mt: 2 }}>
                  <Button
                     color="primary"
                     fullWidth
                     variant="contained"
                     onClick={() => saveForm()}
                     disabled={saved}
                  >
                     {saved ? `Saved` : `Save Customisations`}
                  </Button>
                  <Button
                     sx={{ mt: 2 }}
                     disabled={saved}
                     fullWidth
                     variant="outlined"
                     onClick={async () => {
                        setCustomisations(await getCustomisations(lid));
                        setSaved(true);
                     }}
                  >
                     Discard Changes
                  </Button>
               </Box>
            </Box>
         )}
      </Box>
   );
};

export default Customisations;
