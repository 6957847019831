import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { Box, Typography, Toolbar, AppBar, CircularProgress } from '@mui/material';
import logo from '../../assets/logo.svg';
import Loading from './CheckinForm/loading';
import VisitorForm from './CheckinForm/visitorForm';
import queryString from 'query-string';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { CardSection, PageLayout } from '../layout';
import MultiTenantForm from './CheckinForm/multiTenantForm';
import { sort } from 'ramda';
import { loadImageFromStorage } from './Settings/functions';
const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
   },
   menu: {
      color: theme.palette.text.primary,
   },
   menuButton: {
      marginRight: theme.spacing(2),
   },
   title: {
      flexGrow: 1,
   },
   logo: {
      height: '45px',
   },
   appBar: {
      backgroundImage: 'linear-gradient(16deg, #32bfc7, #349fb8)',
      zIndex: theme.zIndex.drawer + 1,
   },
}));

const VisitorScanIn = (props) => {
   const classes = useStyles();
   let url = queryString.parse(window.location.search);
   const [location, setLocation] = useState(false);
   const [lids, setLids] = useState(false);
   const [llid, setLlid] = useState(false);
   const [landlocation, setLandlocation] = useState(false);
   const [customisations, setCustomisations] = useState(null);
   const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);

   useEffect(() => {
      const fetchPageData = async () => {
         if (url.llid) {
            setLlid(url.llid);
            setLandlocation(url.llocation);
            const landlord = await props.firebase.db.doc(`visitors/${url.llid}`).get();
            if (landlord.exists) {
               let tenantArray = [];
               if (landlord.data().logoPath) {
                  tenantArray.push({
                     lid: url.llid,
                     logoPath: landlord.data().logoPath,
                     displayName: url.llocation,
                  });
               }
               if (landlord.data().tenantDetails) {
                  const tenants = landlord.data().tenantDetails;
                  Object.entries(tenants).map(([key, value]) => {
                     tenantArray.push({
                        lid: key,
                        ...value,
                     });
                     tenantArray.sort((a, b) =>
                        b.displayName.localeCompare(a.displayName),
                     );
                  });
               }
               if (
                  landlord.data().customisations?.welcomeBackgroundImagePath &&
                  landlord.data().customisations?.welcomeBackgroundImagePath !== null
               ) {
                  const url = await loadImageFromStorage(
                     landlord.data().customisations?.welcomeBackgroundImagePath,
                  );
                  setBackgroundImageUrl(url);
               } else {
                  setBackgroundImageUrl(null);
               }
               if (landlord.data().customisations) {
                  setCustomisations(landlord.data().customisations);
               }
               setLids(tenantArray);
            }
         }
         if (url.locName && url.loc) {
            setLocation(url);
         }
      };
      fetchPageData();
      return () => {};
   }, []);

   return (
      <>
         <Box
            sx={{
               position: 'relative',
               width: '100%',
               height: '100vh',
               overflow: 'hidden',
            }}
         >
            {!location && lids && backgroundImageUrl !== null && (
               <Box
                  sx={{
                     position: 'absolute',
                     top: 0,
                     left: 0,
                     width: '100%',
                     height: '100%',
                     backgroundImage: `url(${backgroundImageUrl})`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'center',
                     filter: 'grayscale(100%)',
                     opacity: 0.15,
                     zIndex: -1,
                  }}
               />
            )}
            <AppBar position="fixed" className={classes.appBar}>
               <Toolbar>
                  <Box className={classes.title}>
                     <img src={logo} alt="InTheOffice Logo" className={classes.logo} />
                  </Box>
               </Toolbar>
            </AppBar>
            <PageLayout style={{ backgroundColor: '#f2f2f2' }}>
               {!location && lids ? (
                  <MultiTenantForm
                     setLocation={setLocation}
                     lids={lids}
                     llid={llid}
                     landlocation={landlocation}
                     customisations={customisations}
                  />
               ) : location ? (
                  <VisitorForm
                     location={location}
                     lids={lids}
                     llid={llid}
                     landlocation={landlocation}
                     customisations={customisations}
                  />
               ) : (
                  <Box
                     sx={{
                        mt: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyItems: 'center',
                     }}
                  >
                     <CircularProgress />
                  </Box>
               )}
            </PageLayout>
         </Box>
      </>
   );
};

const VisitorScanInForm = compose(withRouter, withFirebase)(VisitorScanIn);

export default withFirebase(VisitorScanInForm);
