import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import { alpha } from '@material-ui/core/styles';
// import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import enLocale from 'date-fns/locale/en-GB';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
// import { MobileDatePicker } from '@material-ui/pickers';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// import { MobileDatePicker,LocalizationProvider } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import { DateToday } from '../../constants/functions';
import VisitorList from './visitorList';
import NewVisitorForm from './newVisitorForm';
import { Box, Modal } from '@mui/material';
import { check } from 'prettier';
import VisitorListAccordion from './visitorListAccordion';
import { loadVisitors } from '../Visitors/functions';
import EditVisitor from '../Visitors/VisitorDash/editVisitor';
// import OfficeRequests from './requests';
// import firebase from 'firebase/app';

const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
   },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
   drawerPaper: {
      width: '60%',
      [theme.breakpoints.down('sm')]: {
         width: '85%',
      },
   },
   drawer: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   title: {
      marginTop: theme.spacing(4),
   },
   label: {
      marginRight: theme.spacing(1),
   },
   wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
   },
   buttonProgress: {
      // color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
}));

const VisitorsDrawer = (props) => {
   const classes = useStyles();
   const user = JSON.parse(localStorage.getItem('authUser'));
   const [date, setDate] = useState(DateToday());
   const [dayVisitors, setDayVisitors] = useState(false);
   const [disabled, setDisabled] = useState(true);
   const [collapsed, setCollapsed] = useState(false);
   const [editModal, setEditModal] = useState(false);
   const [visitor, setVisitor] = useState(null);

   useEffect(() => {
      const resetState = () => {
         if (!props.visitorsDrawer) {
         }
      };
      resetState();
   }, [props.visitorsDrawer]);

   useEffect(() => {
      if (props.location && props.visitorsDrawer && date) {
         const unsubscribe = props.firebase
            .findHost(props.location.id, date.ref)
            .onSnapshot((result) => {
               if (result.exists && result.data().visitorNo !== 0) {
                  let vis = loadVisitors(result.data());
                  setDayVisitors(vis);
               } else {
                  setDayVisitors({
                     expected: [],
                     checkedIn: [],
                     checkedOut: [],
                  });
               }
            });
         return () => unsubscribe();
      }
   }, [props.location.id, props.form.type, date]);

   function handleDateChange(newDate) {
      let tod = new Date();
      let difference = newDate.getTime() - tod.getTime();
      let differenceInDays = Math.ceil(difference / (1000 * 3600 * 24));
      setDate(DateToday(differenceInDays));
   }

   async function handleSubmit() {
      props.setSaving(true);
   }
   function scroll(day) {
      let tod = new Date();
      let newDate = date.fullDate;
      newDate.setDate(newDate.getDate() + day);
      let difference = newDate.getTime() - tod.getTime();
      let differenceInDays = Math.ceil(difference / (1000 * 3600 * 24));
      if (differenceInDays > 0) {
         setDisabled(false);
      } else {
         setDisabled(true);
      }
      setDate(DateToday(differenceInDays));
   }
   const handleChange = () => {
      setCollapsed((prev) => !prev);
   };
   function handleEditModal(data) {
      setEditModal(true);
      setVisitor(data);
   }

   function closeEditModal() {
      setEditModal(false);
      setVisitor(null);
   }

   return (
      <div className={classes.root}>
         <Modal
            open={editModal}
            onClose={() => closeEditModal}
            sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
            }}
            disableEnforceFocus
            aria-labelledby="modal-modal-titled"
         >
            <Box>
               <EditVisitor
                  date={date}
                  cid={props.location.cid}
                  lid={props.location.id}
                  visitor={visitor}
                  handleEditModal={handleEditModal}
                  closeEditModal={closeEditModal}
               />
            </Box>
         </Modal>
         <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12}>
               <Typography
                  className={classes.title}
                  align="center"
                  component="h1"
                  variant="h5"
               >
                  Manage Visitors
               </Typography>
            </Grid>
            <Grid item>
               {collapsed ? (
                  <Button onClick={handleChange}>hide</Button>
               ) : (
                  <Button variant="outlined" color="primary" onClick={handleChange}>
                     Add
                  </Button>
               )}
            </Grid>
            <Grid item xs={9}>
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                  }}
               >
                  <IconButton
                     disabled={disabled}
                     onClick={() => {
                        scroll(-1);
                     }}
                  >
                     <ArrowBackIosOutlinedIcon />
                  </IconButton>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
                     <DatePicker
                        disablePast
                        minDate={new Date()}
                        value={date.fullDate}
                        onChange={(e) => {
                           handleDateChange(e);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                     />
                  </LocalizationProvider>
                  <IconButton
                     onClick={() => {
                        scroll(1);
                     }}
                  >
                     <ArrowForwardIosOutlinedIcon />
                  </IconButton>
               </Box>
            </Grid>
            <Grid container item xs={12}>
               <Collapse in={collapsed}>
                  <Grid item xs={12}>
                     <NewVisitorForm
                        cid={props.location.cid}
                        lid={props.location.id}
                        date={date.ref}
                        dayVisitors={dayVisitors}
                        setCollapsed={setCollapsed}
                     />
                  </Grid>
               </Collapse>
            </Grid>
            <Grid
               item
               container
               direction="row"
               sx={{ display: 'flex', justifyContent: 'center' }}
               alignItems="center"
               xs={12}
               spacing={2}
            >
               <Grid item xs={12}>
                  {dayVisitors ? (
                     <React.Fragment>
                        {dayVisitors.expected.length > 0 ||
                        dayVisitors.checkedIn.length > 0 ||
                        dayVisitors.checkedOut.length > 0 ? (
                           <Box>
                              <VisitorListAccordion
                                 cid={props.location.cid}
                                 lid={props.location.id}
                                 date={date.ref}
                                 dayVisitors={dayVisitors}
                                 setVisitor={setVisitor}
                                 handleEditModal={handleEditModal}
                              />
                           </Box>
                        ) : (
                           <Typography>
                              Looks like you don't have any visitors today
                           </Typography>
                        )}
                     </React.Fragment>
                  ) : (
                     <Typography className={classes.title} align="center" variant="body1">
                        Loading...
                     </Typography>
                  )}
               </Grid>
            </Grid>
         </Grid>
      </div>
   );
};

export default withFirebase(VisitorsDrawer);
