import React, { useEffect, useState } from 'react';
import {
   Box,
   TableContainer,
   Table,
   TableHead,
   TableRow,
   TableBody,
   TableCell,
} from '@mui/material';

import { withFirebase } from '../../../../Firebase';
import { DateToday, getWeekDatesArray } from '../../../../../constants/functions';

const UserTable = ({ cid, usage, startDate }) => {
   const [allUsers, setAllUsers] = useState([]);
   const [dates, setDates] = useState([]);

   useEffect(() => {
      const unsubscribe = async () => {
         let data = [];
         const today = await getWeekDatesArray(DateToday());
         const lastWeek = await getWeekDatesArray(DateToday(-7));
         const twoWeeksAgo = await getWeekDatesArray(DateToday(-14));
         data.push(...twoWeeksAgo);
         data.push(...lastWeek);
         data.push(...today);
         setDates(data);
      };
      unsubscribe();
   }, []);

   useEffect(() => {
      if (usage !== null) {
         let users = [];
         Object.entries(usage).forEach(([key, value]) => {
            Object.entries(value).forEach(([uid, views]) => {
               if (!users.includes(uid) && uid !== 'totalPageViews') {
                  users.push(uid);
               }
            });
         });
         setAllUsers(users);
      }
   }, [usage]);

   return (
      <Box>
         <TableContainer>
            <Table>
               <TableHead>
                  <TableRow>
                     <TableCell>UID</TableCell>
                     {dates.map((date) => (
                        <TableCell key={date}>{date}</TableCell>
                     ))}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {allUsers.length !== 0 &&
                     allUsers.map((user) => (
                        <TableRow key={user}>
                           <TableCell>{user}</TableCell>
                           {dates.map((date) => {
                              if (usage[date] !== undefined && usage[date][user]) {
                                 return (
                                    <TableCell key={`${date}${user}`}>
                                       {usage[date][user].pageViews}
                                    </TableCell>
                                 );
                              } else {
                                 return <TableCell key={`${date}${user}`}>0</TableCell>;
                              }
                           })}
                        </TableRow>
                     ))}
               </TableBody>
            </Table>
         </TableContainer>
      </Box>
   );
};

export default withFirebase(UserTable);
