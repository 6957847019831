import React from 'react';
import { withFirebase } from '../Firebase';
import { Box, Button } from '@mui/material';

const DayLogs = (props) => {
   const [dayLogs, setDayLogs] = React.useState(null);

   async function loadDayLogs() {
      const dayLogs = await props.firebase.db.doc(`logs/20240719`).get();
      console.log(dayLogs.data());
      // setDayLogs(dayLogs.data());
   }

   return (
      <Box>
         <Button onClick={() => loadDayLogs()}>Load Day Logs</Button>
         {dayLogs !== null &&
            Object.entries(dayLogs).map(([key, value]) => {
               return (
                  <Box key={key}>
                     <h3>{key}</h3>
                     <p>{value}</p>
                  </Box>
               );
            })}
      </Box>
   );
};

export default withFirebase(DayLogs);
