import React, { useState, useEffect } from 'react';
import {
   Box,
   Button,
   CircularProgress,
   Divider,
   TextField,
   Typography,
} from '@mui/material';
import { saveLandlordKey, removeLandlordKey } from './functions';
import TenantImageUpload from './tenantImageUpload';

const TenantVisitor = ({ location, lid, cid }) => {
   const [form, setForm] = useState({
      landlordKey: '',
      displayName: '',
      landLordName: '',
   });
   const [edit, setEdit] = useState(false);
   const [firstTime, setFirstTime] = useState(true);
   const [formDisabled, setFormDisabled] = useState(true);
   const [error, setError] = useState({ error: false, message: '' });

   useEffect(() => {
      if (location.landlordDetails) {
         setForm({ ...location.landlordDetails });
         setFirstTime(false);
      } else {
         setForm({ landlordKey: '', displayName: '', landLordName: '' });
         setEdit(true);
         setFormDisabled(false);
      }
   }, [location]);

   const handleChange = (e) => {
      setForm({ ...form, [e.target.name]: e.target.value });
   };

   async function saveLandlord() {
      setFormDisabled(true);
      const lllid = form.landlordKey.split('*')[0];
      const llcid = form.landlordKey.split('*')[1];
      let path = false;
      if (location.logoPath) {
         path = location.logoPath;
      }
      console.log(cid, lid);
      if (llcid !== '' && llcid !== undefined && lllid !== '' && lllid !== undefined) {
         setError({ error: false, message: '' });
         try {
            await saveLandlordKey(
               llcid,
               lllid,
               cid,
               lid,
               form.displayName,
               firstTime,
               path,
            );
            setEdit(false);
         } catch (error) {
            setFormDisabled(false);
            setError({ error: true, message: 'Please enter a valid landlord key' });
         }
      } else {
         setFormDisabled(false);
         setError({ error: true, message: 'Please enter a valid landlord key' });
      }
   }
   async function removeLandlord() {
      setFormDisabled(true);
      const lllid = form.landlordKey.split('*')[0];
      const llcid = form.landlordKey.split('*')[1];
      try {
         await removeLandlordKey(llcid, lllid, cid, lid);
         setFirstTime(true);
         setFormDisabled(false);
      } catch (error) {
         setFormDisabled(false);
      }
   }
   return (
      <Box
         sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
         }}
      >
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'start',
               justifyContent: 'flex-start',
            }}
         >
            <Typography variant="h6">Link to your Landlord</Typography>
            <Typography variant="body1" paragraph>
               Please speak with your landlord to obtain their key to link your locations.
               Once entered, your location will show on their visitor kiosk.
            </Typography>
            <Typography variant="body1" paragraph>
               At any point you may remove the landlord key to remove the location link.
            </Typography>
         </Box>
         {edit ? (
            <Box
               sx={{
                  maxWidth: '450px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
               }}
            >
               {formDisabled ? (
                  <Box>
                     <CircularProgress />
                  </Box>
               ) : (
                  <Box>
                     {firstTime ? (
                        <TextField
                           fullWidth
                           size="small"
                           label="Landlord Key"
                           name="landlordKey"
                           sx={{ mb: 1 }}
                           value={form.landlordKey}
                           onChange={(e) => handleChange(e)}
                           helperText={error.error ? error.message : ''}
                           error={error.error}
                        />
                     ) : (
                        <Box>
                           <Typography>{form.landlordName}</Typography>
                           <Button onClick={() => removeLandlord()}>Remove</Button>
                        </Box>
                     )}
                     <TextField
                        fullWidth
                        size="small"
                        label="Display Name"
                        name="displayName"
                        sx={{ mb: 1 }}
                        value={form.displayName}
                        onChange={(e) => handleChange(e)}
                     />

                     <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        disabled={formDisabled}
                        onClick={() => saveLandlord()}
                     >
                        Save
                     </Button>
                  </Box>
               )}
            </Box>
         ) : (
            <Box
               sx={{
                  maxWidth: '450px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
               }}
            >
               <Typography variant="body1" paragraph>
                  Landlord: {form.landlordName}
               </Typography>
               <Typography variant="body1" paragraph>
                  Your display name:
                  {form.displayName}
               </Typography>
               <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  onClick={() => {
                     setFormDisabled(false);
                     setEdit(true);
                  }}
               >
                  Edit
               </Button>
            </Box>
         )}
      </Box>
   );
};

export default TenantVisitor;
