import React, { useState, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import { Button } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import firebase from 'firebase/app';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
   content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
   },
   // container: {
   //    paddingTop: theme.spacing(4),
   //    paddingBottom: theme.spacing(4),
   //    paddingLeft: theme.spacing(0),
   // },
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
   card: {
      margin: theme.spacing(2, 0),
      width: '100%',
   },
   cardTitle: {
      margin: theme.spacing(1),
   },
   button: {
      backgroundColor: '#fff',
      borderRadius: 25,
   },
   importButton: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(2),
   },
   drawerPaper: {
      width: '70%',
   },
   drawer: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   deleteButton: {
      margin: theme.spacing(3, 0, 2),
      float: 'right',
   },
   checkbox: {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
         marginLeft: theme.spacing(0),
      },
   },
   container: {
      height: '70vh',
   },
   rowDuplicate: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
   },
   emailError: {
      color: '#f44336',
   },
}));

async function callApi(access_token, call, type) {
   var headers = new Headers();
   var bearer = 'Bearer ' + access_token;
   headers.append('Authorization', bearer);
   if (type === 'groupId') {
      headers.append('ConsistencyLevel', 'eventual');
   }
   var options = {
      method: 'GET',
      headers: headers,
   };
   var graphEndpoint = call;
   let response = await fetch(graphEndpoint, options);
   let data = await response.json();
   return data;
}

const SyncMicrosoftDrawer = (props) => {
   const classes = useStyles();
   const tableData = [
      {
         id: 0,
         givenName: '',
         surname: '',
         mail: '',
         displayName: 'Loading',
         description: '',
         members: 0,
      },
   ];
   const user = JSON.parse(localStorage.getItem('authUser'));
   const { instance, accounts, inProgress } = useMsal();
   const [msUsers, setMsUsers] = useState(tableData);
   const [apiData, setApiData] = useState(null);
   const [selectedRows, setSelectedRows] = useState(null);
   const [loading, setLoading] = useState(false);
   const [selectSyncButton, setSelectSyncButton] = useState(true);
   const [disabled, setDisabled] = useState(false);

   async function convertObjectWithIds(convert) {
      let newObject = {};
      Object.entries(convert).map(([key, value]) => {
         newObject[value.id] = {
            ...value,
         };
      });
      return newObject;
   }
   async function getToken() {
      const accessTokenRequest = {
         scopes: ['Directory.Read.All'],
         account: accounts[0],
      };
      if (inProgress === InteractionStatus.None) {
         instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
               // Acquire token silent success
               let accessToken = accessTokenResponse.accessToken;
               // Call your API with token
               var url = '';
               if (props.importType === 'users') {
                  url = 'https://graph.microsoft.com/v1.0/users';
               } else if (props.importType === 'groups') {
                  url = 'https://graph.microsoft.com/v1.0/groups';
               }

               callApi(accessToken, url, props.importType).then(async (response) => {
                  let data = {};
                  data = await convertObjectWithIds(response.value);
                  if (response['@odata.nextLink']) {
                     var url = response['@odata.nextLink'];
                     let pagination = await callApi(accessToken, url, props.importType);
                     var newData = await convertObjectWithIds(pagination.value);
                     data = Object.assign(data, newData);
                  }
                  let importData = {};
                  let displayData = [];
                  if (props.importType === 'users') {
                     Object.entries(data).map(([key, value]) => {
                        importData[key] = value;
                        if (importData[key].mail) {
                           importData[key].mail = importData[key].mail.toLowerCase();
                        }
                        if (importData[key].userPrincipalName) {
                           importData[key].userPrincipalName =
                              importData[key].userPrincipalName.toLowerCase();
                        }
                        let row = {
                           id: key,
                           firstName: value.givenName,
                           lastName: value.surname,
                           email: value.mail,
                        };
                        if (row.email === null) {
                           row.email = value.userPrincipalName.toLowerCase();
                        } else {
                           row.email = row.email.toLowerCase();
                        }
                        displayData.push(row);
                     });
                  }
                  setApiData(importData);
                  if (props.importType === 'groups') {
                     Object.entries(data).map(([key, value]) => {
                        let row = {
                           id: key,
                           displayName: value.displayName,
                           description: value.description,
                           visibility: value.visibility,
                        };
                        displayData.push(row);
                     });
                  }
                  setMsUsers(displayData);
               });
            })
            .catch((error) => {
               if (error instanceof InteractionRequiredAuthError) {
                  console.log(error, 'error, instance');
                  instance.acquireTokenRedirect(accessTokenRequest);
               }
               console.log(error, 'normal error');
            });
      }
   }
   var columns = [];
   if (props.importType === 'users') {
      columns = [
         { field: 'id', headerName: 'ID', width: 70 },
         { field: 'firstName', headerName: 'First name', width: 150 },
         { field: 'lastName', headerName: 'Last name', width: 150 },
         { field: 'email', headerName: 'Email', width: 250 },
      ];
   } else if (props.importType === 'groups') {
      columns = [
         { field: 'id', headerName: 'ID', width: 70 },
         { field: 'displayName', headerName: 'Name', width: 200 },
         { field: 'description', headerName: 'Description', width: 250 },
         { field: 'visibility', headerName: 'Visibility', width: 150 },
      ];
   }
   useEffect(() => {
      const loadMSUSers = async () => {
         if (props.syncMicrosoftDrawer) {
            getToken();
         }
      };
      loadMSUSers();
   }, [props.syncMicrosoftDrawer, props.importType]);

   async function handleSelection(newSelection) {
      if (newSelection.length > 0) {
         setSelectSyncButton(false);
      } else {
         setSelectSyncButton(true);
      }
      var data = {};
      newSelection.map((user) => {
         data[user] = apiData[user];
      });

      setSelectedRows(data);
   }

   async function handleSync(all) {
      setDisabled(true);
      setSelectSyncButton(true);
      setLoading('loading');
      let visitorsEnabled = false;
      if (props.company?.features?.visitors || props.company?.features?.spaceBookings) {
         visitorsEnabled = true;
      }
      var data = {
         existingUsers: {},
         syncUsers: {},
         displayFilter: props.displayFilter,
         visitorsEnabled,
      };
      var existingUsers = await props.firebase.companyPeople(user.cid).get();
      if (!existingUsers.empty) {
         existingUsers.forEach((doc) => {
            data.existingUsers[doc.data().email] = {
               uid: doc.id,
               email: doc.data().email.toLowerCase(),
            };
         });
      }
      if (all) {
         data.syncUsers = apiData;
      } else {
         data.syncUsers = selectedRows;
      }
      console.log(data);
      var completeSync = firebase.functions().httpsCallable('syncMSUsers');
      await completeSync(data).then(() => {
         setLoading('done');
         // //   setUserState('new');
      });
   }

   const handleClose = () => {
      props.setSyncMicrosoftDrawer(false);
      setLoading(false);
      setMsUsers(tableData);
      setApiData(null);
   };
   return (
      <Drawer
         classes={{ paper: classes.drawerPaper }}
         anchor="right"
         open={props.syncMicrosoftDrawer}
         onClose={() => handleClose()}
      >
         <Container maxWidth="md">
            <div className={classes.drawer}>
               {props.importType === 'users' ? (
                  <Typography component="h1" variant="h5">
                     Microsoft User Sync
                  </Typography>
               ) : (
                  <Typography component="h1" variant="h5">
                     Microsoft Group Sync
                  </Typography>
               )}
               <Card className={classes.card}>
                  <Grid container alignItems="center" spacing={2}>
                     <Grid item xs={12} sm={6}>
                        <Button
                           size="small"
                           variant="outlined"
                           color="primary"
                           disabled={selectSyncButton}
                           onClick={() => handleSync()}
                        >
                           Sync Selected
                        </Button>
                     </Grid>

                     <Grid item xs={12} sm={6}>
                        <Button
                           size="small"
                           variant="contained"
                           color="primary"
                           disabled={disabled}
                           onClick={() => handleSync(true)}
                        >
                           Sync All
                        </Button>
                     </Grid>
                     {!loading && (
                        <Grid item xs={12}>
                           <div style={{ height: '70vh', width: '100%' }}>
                              <DataGrid
                                 rows={msUsers}
                                 columns={columns}
                                 pageSize={100}
                                 checkboxSelection
                                 onSelectionModelChange={(newSelection) => {
                                    handleSelection(newSelection);
                                 }}
                              />
                           </div>
                        </Grid>
                     )}
                     {loading === 'loading' && (
                        <Grid item xs={12}>
                           <Typography variant="h6">Syncing...</Typography>
                           <CircularProgress />
                        </Grid>
                     )}
                     {loading === 'done' && (
                        <Grid item xs={12}>
                           <Typography variant="h6">Done</Typography>
                           <Button onClick={() => handleClose()}>Close</Button>
                        </Grid>
                     )}
                  </Grid>
               </Card>
            </div>
         </Container>
      </Drawer>
   );
};

export default withFirebase(SyncMicrosoftDrawer);
