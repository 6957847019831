import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import firebase from 'firebase/app';
import { makeStyles } from '@material-ui/core/styles';
import * as ROUTES from '../../constants/routes';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
   TextField,
   Button,
   Link,
   FormControl,
   FormLabel,
   FormControlLabel,
   Checkbox,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
      minHeight: '70vh',
      maxWidth: '100%',
      marginTop: theme.spacing(5),
   },
}));

const NewCompanyForm = (props) => {
   const classes = useStyles();
   const [form, setForm] = useState({
      firstName: '',
      lastName: '',
      companyName: '',
      termsAndPrivacy: false,
      allowExtraEmails: false,
   });
   const [validForm, setValidForm] = useState(true);
   const [error, setError] = useState({
      firstName: false,
      lastName: false,
      companyName: false,
   });
   const [loading, setLoading] = useState(false);
   async function loadPersonIntoCompany(user, result, pendingCred) {
      let newUser = {};
      let newUserRecord = {};
      result.forEach((doc) => {
         var newPassword =
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
         newUser = {
            ...doc.data(),
            uid: doc.id,
            email: doc.data().email,
            emailVerified: true,
            displayName: doc.data().name.firstName + ' ' + doc.data().name.lastName,
            password: newPassword,
            marketing: { optIn: false },
            roles: { USER: 'STAFF' },
            status: { statusId: 'active' },
         };
         newUserRecord = { ...doc.data(), uid: doc.id };
      });
      var deleteNewAccount = firebase.functions().httpsCallable('deleteUser');
      var mergeAccounts = firebase.functions().httpsCallable('SSOMergeUser');
      var convertAccount = firebase.functions().httpsCallable('linkNonVerifiedUser');
      await deleteNewAccount(user.uid)
         .then(async () => {
            await mergeAccounts(newUser).then(async () => {
               await props.firebase
                  .doSignInWithEmailAndPassword(newUser.email, newUser.password)
                  .then(async function (result) {
                     return result.user.linkWithCredential(pendingCred);
                  })
                  .then(async function () {
                     var status = { statusId: 'active' };
                     await props.firebase
                        .getPerson(newUserRecord.cid, newUserRecord.uid)
                        .set({ status }, { merge: true });
                     return;
                  });
            });
         })
         .catch((error) => {
            console.log(error, 'error');
         });
   }

   async function handleSubmit(e) {
      e.preventDefault();
      setLoading('loading');
      var newCompanyKey = await props.firebase.companies().doc();
      var newLocationKey = await props.firebase.locations().doc();
      var date = new Date();
      /// need to add a button once this function has run to go to the homepage.
      var data = {
         uid: props.mSCreds.user.uid,
         email: props.mSCreds.user.email,
         name: {
            firstName: form.firstName,
            lastName: form.lastName,
         },
         displayName: props.mSCreds.user.displayName,
         cid: newCompanyKey.id,
         lid: newLocationKey.id,
         gid: [],
         marketing: {
            optIn: form.allowExtraEmails,
         },
         profile: {
            creationDate: date,
            termsAgreed: date,
         },
         companyName: form.companyName,
         subscription: {
            plan: 'essential',
            planStartDate: date,
         },
         domains: {
            used: [props.emailDomain.toLowerCase()],
            allowed: [],
         },
         roles: { ADMIN: 'ADMIN' },
         status: { statusId: 'user1' },
         password:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
         date,
      };
      console.log(data);

      var newUser = firebase.functions().httpsCallable('SSONewUser');
      var newSetUp = firebase.functions().httpsCallable('SSONewCompanyAndUser');
      var sendInitialWelcomeEmail = firebase
         .functions()
         .httpsCallable('sendInitialWelcomeEmail-sendInitialWelcomeEmail');
      await newUser(data).then(async () => {
         await newSetUp(data).then(async () => {
            await props.firebase
               .doSignInWithEmailAndPassword(data.email, data.password)
               .then(async function (result) {
                  return result.user.linkWithCredential(props.mSCreds.credential);
               })
               .then(async function (result) {
                  return await sendInitialWelcomeEmail({
                     email: data.email,
                     name: data.name,
                     companyName: data.companyName,
                     type: 'microsoft',
                  });
               })
               .then(async () => {
                  if (props.msTeams) {
                     props.history.push(ROUTES.MSTEAMSAUTH);
                  } else {
                     setLoading('done');
                  }
               });
         });
      });
   }

   useEffect(() => {
      const newCompanyFormSetup = async () => {
         if (props.userState === 'new') {
            let displayName = props.mSCreds.user.displayName.split(' ');
            let firstName = '';
            let lastName = '';
            if (displayName.length === 1) {
               firstName = displayName[0];
            } else if (displayName.length === 2) {
               firstName = displayName[0];
               lastName = displayName[1];
            } else if (displayName.length > 2) {
               firstName = displayName[0];
               for (var i = 1; i < displayName.length; i++) {
                  if (i !== displayName.length - 1) {
                     lastName += displayName[i] + ' ';
                  } else {
                     lastName += displayName[i];
                  }
               }
            }
            setForm({ ...form, firstName, lastName });
         }
      };
      newCompanyFormSetup();
   }, [props.mSCreds, props.userState]);

   const handleChange = async (e) => {
      setForm({ ...form, [e.target.name]: e.target.value });
      if (
         e.target.value === '' ||
         form.termsAndPrivacy !== true ||
         form.firstName === '' ||
         form.lastName === '' ||
         form.companyName === ''
      ) {
         setValidForm(true);
      } else {
         setValidForm(false);
      }
   };
   const toggleOption = async (e) => {
      var value = !form[e.target.name];
      setForm({ ...form, [e.target.name]: value });
      if (e.target.name === 'termsAndPrivacy') {
         if (
            value === true &&
            form.firstName !== '' &&
            form.lastName !== '' &&
            form.companyName !== ''
         ) {
            setValidForm(false);
         } else {
            setValidForm(true);
         }
      }
   };

   return (
      <Container component="main" maxWidth="xs">
         <div className={classes.paper}>
            {!loading && (
               <React.Fragment>
                  <Grid item xs={12} align="center">
                     <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                     </Avatar>
                  </Grid>
                  <Typography component="h1" variant="h5" align="center">
                     Hi {props.mSCreds.user.displayName}.
                  </Typography>
                  <Typography component="h2" variant="h6" paragraph align="center">
                     Complete your registration
                  </Typography>
                  <Typography variant="body1" paragraph align="center">
                     Please complete the form below and click on Sign Up.
                  </Typography>
                  <form className={classes.form} onSubmit={handleSubmit} noValidate>
                     <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                           <TextField
                              autoComplete="fname"
                              name="firstName"
                              variant="outlined"
                              required
                              fullWidth
                              id="firstName"
                              label="First Name"
                              value={form.firstName}
                              error={error.firstName}
                              onInput={handleChange}
                              autoFocus
                           />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                           <TextField
                              variant="outlined"
                              required
                              fullWidth
                              id="lastName"
                              label="Last Name"
                              name="lastName"
                              autoComplete="lname"
                              value={form.lastName}
                              error={error.lastName}
                              onInput={handleChange}
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <TextField
                              variant="outlined"
                              required
                              fullWidth
                              id="companyName"
                              label="Company Name"
                              name="companyName"
                              value={form.companyName}
                              error={error.companyName}
                              onInput={handleChange}
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <FormControl>
                              <FormLabel component="legend">Marketing</FormLabel>
                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       name="allowExtraEmails"
                                       checked={form.allowExtraEmails}
                                       onChange={toggleOption}
                                       color="primary"
                                    />
                                 }
                                 label="I want to receive inspiration, marketing promotions and updates via email."
                              />
                           </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                           <FormControl required component="fieldset">
                              <FormLabel component="legend">Agreement</FormLabel>
                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       name="termsAndPrivacy"
                                       required
                                       checked={form.termsAndPrivacy}
                                       onChange={toggleOption}
                                       color="primary"
                                    />
                                 }
                                 label="I have read and accept the General Subscription Terms & Conditions, the End User License and the Privacy Policy below"
                              />
                              {/* <FormHelperText>{termsAndPrivacyHelp}</FormHelperText> */}
                           </FormControl>
                           <Typography variant="body1">
                              <Link
                                 href="https://intheoffice.io/legal/terms"
                                 target="_blank"
                              >
                                 General Subscription Terms & Conditions
                              </Link>
                              , &nbsp;
                              <Link
                                 href="https://intheoffice.io/legal/enduseragreement"
                                 target="_blank"
                              >
                                 End User Agreement
                              </Link>
                              &nbsp;and&nbsp;
                              <Link
                                 href="https://intheoffice.io/legal/privacy"
                                 target="_blank"
                              >
                                 Privacy Policy
                              </Link>
                           </Typography>
                        </Grid>
                     </Grid>
                     <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={validForm}
                        className={classes.submit}
                     >
                        Sign Up
                     </Button>
                  </form>
               </React.Fragment>
            )}
            {loading === 'loading' && (
               <React.Fragment>
                  <Grid item xs={12} align="center">
                     <Typography variant="h5" component="h2" paragraph align="center">
                        Almost there...
                     </Typography>
                     <CircularProgress />
                  </Grid>
               </React.Fragment>
            )}
            {loading === 'done' && (
               <React.Fragment>
                  <Grid item xs={12} align="center">
                     <Typography variant="h5" component="h2" paragraph align="center">
                        All done!
                     </Typography>
                     <Button
                        href={ROUTES.DASHBOARD}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                     >
                        Go to your dashboard
                     </Button>
                  </Grid>
               </React.Fragment>
            )}
         </div>
      </Container>
   );
};

const NewCompany = compose(withRouter, withFirebase)(NewCompanyForm);
export default NewCompany;
