import React, { useState } from 'react';
import {
   Box,
   Button,
   IconButton,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Menu,
   MenuItem,
   Stack,
   Typography,
} from '@mui/material';
import { useTheme } from '@material-ui/core/styles';
import { spaceTypeImage } from '../Spaces/functions';
import SpaceMap from './spaceMap';
import RoomIcon from '@material-ui/icons/Room';
import Image from 'material-ui-image';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withFirebase } from '../Firebase';

const BookedSpacesListItem = (props) => {
   const theme = useTheme();
   const [anchorEl, setAnchorEl] = useState(null);
   const [map, setMap] = useState(false);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };
   async function showMap() {
      setMap(true);
   }

   async function cancelSpace() {
      let data = {
         user: props.user,
         space: {
            [props.booking.bid]: props.booking,
         },
         date: props.date,
      };
      try {
         await props.firebase.cancelBookedSpaceForUser(data);
         handleClose();
      } catch (error) {
         console.log(error, 'error');
      }
   }
   function loadTime(first, second) {
      var start = new Date(first.seconds * 1000);
      var end = new Date(second.seconds * 1000);
      var starthours = start.getHours();
      var startmins = start.getMinutes();
      var endhours = end.getHours();
      var endmins = end.getMinutes();
      var time =
         ('0' + starthours).slice(-2) +
         ':' +
         ('0' + startmins).slice(-2) +
         ' - ' +
         ('0' + endhours).slice(-2) +
         ':' +
         ('0' + endmins).slice(-2);
      return time;
   }

   return (
      <React.Fragment>
         {props.booking && props.booking.mapPath && (
            <SpaceMap
               spaceKey={props.booking.sid}
               locationKey={props.booking.lid}
               map={map}
               setMap={setMap}
            />
         )}
         {props.booking !== null && (
            <ListItem
               alignItems="flex-start"
               sx={{
                  pt: 0,
                  pb: 0,
                  borderLeft: props.booking.backgroundColor ? `4px solid` : '0px solid',
                  borderLeftColor: props.booking.backgroundColor
                     ? props.booking.backgroundColor
                     : '#C8C8C8',
               }}
               secondaryAction={
                  <Stack direction="row" spacing={1}>
                     {props.booking.mapPath && (
                        <Button startIcon={<RoomIcon />} onClick={() => showMap()}>
                           Map
                        </Button>
                     )}
                     <IconButton
                        aria-label="more-menu"
                        size="small"
                        onClick={handleClick}
                     >
                        <MoreVertIcon fontSize="small" />
                     </IconButton>

                     <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                     >
                        <MenuItem onClick={() => cancelSpace()}>Cancel</MenuItem>
                     </Menu>
                  </Stack>
               }
            >
               <ListItemAvatar>
                  <Box
                     sx={{
                        maxWidth: '35px',
                        maxHeight: '35px',
                        marginRight: 1,
                     }}
                  >
                     <Image src={spaceTypeImage(props.booking.typeName)} />
                  </Box>
               </ListItemAvatar>
               <ListItemText
                  primary={
                     <Typography sx={{ fontSize: '0.90rem' }}>
                        {props.booking.name} -{' '}
                        {props.booking.type
                           ? props.booking.type
                           : loadTime(props.booking.start, props.booking.end)}
                     </Typography>
                  }
                  secondary={
                     <Typography
                        variant="caption"
                        sx={{ fontSize: '0.75rem', color: theme.palette.text.secondary }}
                     >
                        {!props.booking.type && props.booking.title
                           ? props.booking.title
                           : props.booking.parent
                           ? props.booking.parentDetail.name
                           : ''}
                     </Typography>
                  }
               />
            </ListItem>
         )}
      </React.Fragment>
   );
};

export default withFirebase(BookedSpacesListItem);
