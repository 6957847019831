import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Tab } from '@mui/material';
import * as ROUTES from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
   container: {
      display: 'flex',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
   },
}));

const CompanyRow = (props) => {
   function handleExpand(event) {
      event.stopPropagation();
      console.log(props.company.cid, props.expanded[props.company.cid]);
      props.setExpanded({
         ...props.expanded,
         [props.company.cid]: !props.expanded[props.company.cid],
      });
   }

   return (
      <TableRow
         key={props.company.cid}
         hover
         onClick={() => props.setSelectedCompany(props.company)}
         sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
         <TableCell component="th" scope="row">
            <Typography>{props.company.companyName}</Typography>
            <Typography variant="caption">{props.company.cid}</Typography>
         </TableCell>
         <TableCell align="right">
            {props.company.profile.creationDate.toDate().toDateString()}
         </TableCell>
         <TableCell align="right">
            {props.company.totalPeople ? props.company.totalPeople : 'ACTION'}
         </TableCell>
         <TableCell align="right">{props.company.locCount}</TableCell>
         <TableCell align="right">
            {props.company.subscription.plan}
            {props.company.currentBillTotal && (
               <Typography>£{props.company.currentBillTotal.toFixed(2)}</Typography>
            )}
            {props.company.nextBillingDate && (
               <Typography>
                  {props.company.nextBillingDate.toDate().toDateString()}
               </Typography>
            )}
         </TableCell>
         <TableCell align="right">
            {props.company.features && (
               <Typography variant="caption">
                  {Object.entries(props.company.features).map(([key, value]) => {
                     if (value) {
                        return `${key} , `;
                     }
                  })}
               </Typography>
            )}
         </TableCell>
         <TableCell align="right">
            <Button
               onClick={(e) => {
                  handleExpand(e);
               }}
            >
               Expand
            </Button>
         </TableCell>
      </TableRow>
   );
};
const LocationsRows = (props) => {
   return (
      <React.Fragment>
         {Object.entries(props.locations).map(([key, value]) => (
            <TableRow
               key={key}
               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
               hover
               onClick={() => props.setSelectedLocation(value)}
            >
               <TableCell component="th" scope="row"></TableCell>
               <TableCell align="right">{value.name}</TableCell>
               <TableCell align="right">{value.people}</TableCell>
               <TableCell align="right"></TableCell>
               <TableCell align="right"></TableCell>
               <TableCell align="right">
                  <List dense>
                     {value.groups && <ListItem>Groups</ListItem>}
                     {value.offsiteEnabled && <ListItem>Offsite</ListItem>}
                     {value.reporting && <ListItem>Reporting</ListItem>}
                     {value.routine && <ListItem>Routine</ListItem>}
                     {value.spacesEnabled && <ListItem>Spaces</ListItem>}
                     {value.visitorsEnabled && <ListItem>Visitors</ListItem>}
                     {value.actions && value.actions.requests && (
                        <ListItem>Requests</ListItem>
                     )}
                     {value.actions && value.actions.meetings && (
                        <ListItem>Meetings</ListItem>
                     )}
                  </List>
               </TableCell>
            </TableRow>
         ))}
      </React.Fragment>
   );
};

const CompaniesOverview = (props) => {
   const classes = useStyles();
   const [expanded, setExpanded] = useState({});
   const [subscription, setSubscription] = useState('premium');
   const [premiumCount, setPremiumCount] = useState(0);
   const [demoCount, setDemoCount] = useState(0);
   const [downgradedCount, setDowngradedCount] = useState(0);
   const [essentialCount, setEssentialCount] = useState(0);
   useEffect(() => {
      if (props.companies) {
         var premium = 0;
         var essential = 0;
         var demos = 0;
         var downgraded = 0;
         var exp = {};
         props.companies.map((company) => {
            if (company.subscription.plan === 'demo') {
               demos = demos + 1;
            } else if (company.subscription.plan === 'downgraded') {
               downgraded = downgraded + 1;
            } else if (company.subscription.plan === 'premium') {
               premium = premium + 1;
            } else {
               essential = essential + 1;
            }
            exp[company.cid] = false;
         });
         setExpanded(exp);
         setPremiumCount(premium);
         setEssentialCount(essential);
         setDemoCount(demos);
         setDowngradedCount(downgraded);
      }
   }, [props.companies]);
   return (
      <React.Fragment>
         <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
               <Button onClick={() => setSubscription('premium')}>Premium</Button>{' '}
               <Button onClick={() => setSubscription('essential')}>Essential</Button>{' '}
               <Button onClick={() => setSubscription('downgraded')}>Downgraded</Button>{' '}
               <Button onClick={() => setSubscription('demo')}>Demos</Button>
            </Box>
            <Box>
               <Button href={ROUTES.GLOBALREPORTINGUSAGE}>GO to usage overview</Button>
            </Box>
         </Box>

         <Paper sx={{ ml: 1, mr: 2, mb: 2, p: 2 }}>
            <Typography variant="h6" component="h2" paragraph>
               Summary:
            </Typography>
            <Typography variant="body1" component="p" paragraph>
               Premium: {premiumCount}, Essential:{essentialCount}, Downgraded:
               {downgradedCount}, Demos:{demoCount}
            </Typography>
         </Paper>
         {!props.selectedLocation && (
            <TableContainer
               className={classes.container}
               component={Paper}
               sx={{ ml: 1, mr: 2 }}
            >
               <Table sx={{ minWidth: 650 }} aria-label="all-companies">
                  <TableHead>
                     <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell align="right">Created</TableCell>
                        <TableCell align="right">Total People</TableCell>
                        <TableCell align="right">Locations</TableCell>
                        <TableCell align="right">Subscription</TableCell>
                        <TableCell align="right">Features</TableCell>
                        <TableCell align="right">Expand</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {!props.companies ? (
                        <Typography>Loading...</Typography>
                     ) : (
                        <React.Fragment>
                           {props.companies.map((company) => {
                              if (company.subscription.plan === subscription) {
                                 return (
                                    <React.Fragment key={company.cid}>
                                       <CompanyRow
                                          company={company}
                                          setSelectedCompany={props.setSelectedCompany}
                                          setExpanded={setExpanded}
                                          expanded={expanded}
                                       />
                                       {expanded[company.cid] && (
                                          <LocationsRows
                                             setSelectedLocation={
                                                props.setSelectedLocation
                                             }
                                             locations={company.locData}
                                          />
                                       )}
                                    </React.Fragment>
                                 );
                              }
                           })}
                        </React.Fragment>
                     )}
                  </TableBody>
               </Table>
            </TableContainer>
         )}
      </React.Fragment>
   );
};

export default CompaniesOverview;
