import React, { useState, useEffect } from 'react';
import { useFirebase, withFirebase } from '../../../Firebase';
import {
   CardSection,
   PageLayout,
   TabSet,
   TabSetContent,
   useTabSet,
} from '../../../layout';
import { Box, Divider, Typography } from '@mui/material';
import CompaniesUsage from './companyTab/companiesUsage';
import UsersUsage from './usersUsage';
import OverviewUsage from './overviewUsage';
import Loading from '../../../Loading';
import { id } from 'date-fns/locale';

const Dashboard = (props) => {
   const firebase = useFirebase();
   const [tabValue, _, handleTabChange] = useTabSet();
   const tabNames = ['Companies', 'Users'];
   const [companies, setCompanies] = useState(null);

   useEffect(() => {
      firebase.db
         .collection(`company`)
         .get()
         .then((results) => {
            console.log(results, 'results');
            let companies = {};
            results.forEach((doc) => {
               companies[doc.id] = { cid: doc.id, ...doc.data() };
            });
            console.log(companies, 'companies');
            setCompanies(companies);
         });
   }, []);
   return (
      <Box>
         <CardSection sx={{ mb: 2 }}>
            {companies !== null ? <OverviewUsage companies={companies} /> : <Loading />}
         </CardSection>

         <TabSet value={tabValue} tabNames={tabNames} onChange={handleTabChange} />

         <Divider />
         <TabSetContent
            tabNames={tabNames}
            tabValue={tabValue}
            contentArray={[
               <CompaniesUsage tab={props.tab} companies={companies} />,
               <UsersUsage tab={props.tab} />,
            ]}
         />
      </Box>
   );
};

export default withFirebase(Dashboard);
