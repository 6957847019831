import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { withFirebase } from '../Firebase';
import {
   AuthenticatedTemplate,
   UnauthenticatedTemplate,
   useMsal,
} from '@azure/msal-react';
import * as ROUTES from '../../constants/routes';
import CompanyAdmins from '../GlobalReporting/companyAdmins';

const useStyles = makeStyles((theme) => ({
   root: {
      marginTop: theme.spacing(2),
   },
   container: {
      padding: theme.spacing(2, 2),
      width: '100%', // Fix IE 11 issue.
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
   button: {
      marginLeft: theme.spacing(2),
   },
}));

const Users = (props) => {
   const classes = useStyles();
   const user = JSON.parse(localStorage.getItem('authUser'));
   const [company, setCompany] = useState({ companyName: 'Loading...' });
   const [domains, setDomains] = useState(false);
   const { instance } = useMsal();
   useEffect(() => {
      const loadCompany = async () => {
         if (props.tab === 2) {
            let result = await props.firebase.company(user.cid).get();
            let details = result.data();
            setCompany({ ...details, cid: result.id });
            let domainSettings = {};
            result.data().domains.used.map((dom) => {
               if (result.data().domains.allowed.includes(dom)) {
                  domainSettings[dom] = true;
               } else {
                  domainSettings[dom] = false;
               }
            });
            setDomains(domainSettings);
         }
      };
      loadCompany();
   }, []);

   const handleChange = async (event) => {
      let data = { ...domains, [event.target.name]: event.target.checked };
      setDomains(data);
      const domId = await props.firebase
         .getDomainByCo(user.cid)
         .get()
         .then((result) => {
            if (!result.empty) {
               return result.docs[0].id;
            }
         });
      await props.firebase.changeDomains(data, user.cid, domId);
   };

   async function loginAndReturn() {
      await window.history.replaceState(null, 'Title', '/settings?tab=2');
      await instance.loginRedirect();
   }

   return (
      <React.Fragment>
         <Paper className={classes.container}>
            <Grid
               container
               direction="column"
               alignItems="stretch"
               spacing={3}
               className={classes.root}
            >
               <Grid item xs={12}>
                  <Typography component="h2" variant="h5">
                     {company.companyName}
                  </Typography>
               </Grid>
               <Grid item xs={12}>
                  <CompanyAdmins company={company} />
               </Grid>
               <Grid item xs={12}>
                  <Typography variant="h6" component="h3">
                     Single Sign On Rules
                  </Typography>
                  <Typography variant="body1">
                     The People that have been added have the following email address
                     domain name. Click on 'Allow self sign up" by the domain names where
                     users can sign up to intheOffice without being added on the people
                     page first.
                  </Typography>
                  <TableContainer component={Paper}>
                     <Table>
                        <TableHead>
                           <TableRow>
                              <TableCell>Domain</TableCell>
                              <TableCell>Allow self sign up</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {domains &&
                              Object.entries(domains).map(([key, value]) => (
                                 <TableRow key={key}>
                                    <TableCell component="th" scope="row">
                                       {key}
                                    </TableCell>
                                    <TableCell>
                                       <Switch
                                          checked={value}
                                          onChange={handleChange}
                                          color="primary"
                                          name={key}
                                          inputProps={{
                                             'aria-label': { key } + ' allowed',
                                          }}
                                       />
                                    </TableCell>
                                 </TableRow>
                              ))}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </Grid>
               <Grid item xs={12}>
                  <Typography variant="h6" component="h3">
                     Sync with Microsoft
                  </Typography>
                  <Typography variant="body1">
                     If your company uses Office 365, you can sync users and groups with
                     intheOffice. You will need to follow the process below so that you
                     have:
                  </Typography>
                  <Typography variant="body1">
                     1) logged into intheOffice via single sign-on;
                  </Typography>
                  <Typography variant="body1">2) logged into Office 365; and</Typography>
                  <Typography variant="body1">
                     3) granted intheOffice permission to sync your users and groups.
                  </Typography>
               </Grid>
               <Grid container item xs={12}>
                  {user.providerData[0].providerId === 'microsoft.com' ? (
                     <React.Fragment>
                        <UnauthenticatedTemplate>
                           <div
                              style={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 flexWrap: 'wrap',
                              }}
                           >
                              <Typography variant="body1">
                                 Your intheOffice account is linked to Microsoft single
                                 sign-on, but now need log into Office 365.
                              </Typography>
                              <Button
                                 className={classes.button}
                                 variant="outlined"
                                 color="primary"
                                 onClick={() => loginAndReturn()}
                              >
                                 Log into Office 365
                              </Button>
                           </div>
                        </UnauthenticatedTemplate>
                        <AuthenticatedTemplate>
                           <div
                              style={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 flexWrap: 'wrap',
                              }}
                           >
                              <Typography variant="body1">
                                 You are set up to sync Users and Groups from the People
                                 page.
                              </Typography>
                              <Link className={classes.button} href={ROUTES.PEOPLE}>
                                 Go to People page
                              </Link>
                           </div>
                        </AuthenticatedTemplate>
                     </React.Fragment>
                  ) : (
                     <React.Fragment>
                        <div
                           style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                           }}
                        >
                           <Typography variant="body1" paragraph>
                              You will need to link your intheOffice account to Microsoft
                              single sign-on.
                           </Typography>
                           <Button
                              className={classes.button}
                              variant="outlined"
                              href={ROUTES.LOGGEDINAUTH}
                           >
                              Link with Microsoft
                           </Button>
                        </div>
                     </React.Fragment>
                  )}
               </Grid>
            </Grid>
         </Paper>
      </React.Fragment>
   );
};
export default withFirebase(Users);
