import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import PastMonths from './pastMonths';
import CompanyAdmins from './companyAdmins';
import { Box } from '@mui/material';
import CompanyLogs from './Company/companyLogs';
import CompanyTabs from './Company';
import firebase from 'firebase/app';

const useStyles = makeStyles((theme) => ({
   container: {
      display: 'flex',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
   },
}));

const CompanyOverview = (props) => {
   const classes = useStyles();
   const [companyPeople, setCompanyPeople] = useState(false);
   const [admins, setAdmins] = useState(false);
   const [userMetadata, setUserMetadata] = useState(false);

   useEffect(() => {
      if (props.selectedCompany) {
         const unsubscribe = props.firebase
            .companyPeople(props.selectedCompany.cid)
            .onSnapshot((results) => {
               let ppl = {};
               let add = [];
               results.forEach((result) => {
                  ppl[result.id] = result.data();
                  if (
                     result.data().locationAdmin &&
                     result.data().locationAdmin.length > 0
                  ) {
                     add.push({ uid: result.id, ...result.data() });
                  }
               });
               setCompanyPeople(ppl);
               setAdmins(add);
            });
         return () => unsubscribe();
      }
   }, [props.selectedCompany]);

   async function handleChange(event) {
      let newAdminArray = [];
      admins.map((admin) => {
         newAdminArray.push(admin.uid);
      });
      if (!newAdminArray.includes(event.target.value)) {
         newAdminArray.push(event.target.value);
      }
      let locations = [];
      Object.keys(props.selectedCompany.locations).map((location) => {
         locations.push(location);
      });
      try {
         await props.firebase.saveLocationAdmin(
            event.target.value,
            props.selectedCompany.cid,
            locations,
            newAdminArray,
         );
      } catch (error) {
         console.log(error, 'error');
      }
   }
   async function deleteAdmin(user) {
      let newAdminArray = [];
      admins.map((admin) => {
         if (admin.uid !== user.uid) {
            newAdminArray.push(admin.uid);
         }
      });
      let locations = [];
      Object.keys(props.selectedCompany.locations).map((location) => {
         locations.push(location);
      });
      try {
         await props.firebase.deleteLocationAdmin(
            user.uid,
            props.selectedCompany.cid,
            locations,
            newAdminArray,
         );
      } catch (error) {
         console.log(error, 'error');
      }
   }

   async function actionClick() {
      // domain
      // var domain = await props.firebase.db.collection(`domains`).where('cid', '==', props.selectedCompany.cid).get();
      // domain.forEach(domain => {
      // await props.firebase.db.doc(`domains/${domain.id}`).delete();
      // })
      //people
      var people = await props.firebase
         .companyPeople(props.selectedCompany.cid)
         .get()
         .then((results) => {
            let ppl = {};
            results.forEach((result) => {
               if (result.data().cid !== props.selectedCompany.cid) {
                  ppl[result.id] = result.data();
               }
            });
            return ppl;
         });
      console.log(people);
      for await (let person of people) {
         var schedule = await props.firebase
            .personScheduleExists(props.selectedCompany.cid, person)
            .get();
      }
   }

   async function runReport() {
      var people = {};
      var uids = await props.firebase.db
         .doc(`company/${props.selectedCompany.cid}/indexes/people`)
         .get()
         .then((result) => {
            let list = [];
            Object.entries(result.data()).map(([uid, value]) => {
               list.push(uid);
               people[uid] = {
                  uid: uid,
                  email: value.email,
               };
            });
            return list;
         });
      console.log(uids);
      console.log('report');
      var getUser = firebase.functions().httpsCallable('reporting-userMetadata');
      getUser({ uids })
         .then((results) => {
            console.log(results, 'SUCCESS');
            results.data.map((result) => {
               people[result.uid] = { ...people[result.uid], ...result };
            });
            setUserMetadata(people);
         })
         .catch((error) => {
            console.warn(error, 'Error getting user metadata');
         });
   }

   return (
      <Grid container className={classes.container} spacing={3}>
         <Grid item xs={6}>
            <Button onClick={() => props.setSelectedCompany(false)}>Back</Button>
         </Grid>
         <Grid item xs={6}>
            <Button color="secondary" onClick={() => actionClick()}>
               Action
            </Button>
         </Grid>
         <Grid item xs={12}>
            <Box
               component={Paper}
               sx={{ p: 3, display: 'flex', flexDirection: 'column' }}
            >
               <Box>
                  <Typography variant="h5">
                     {props.selectedCompany.companyName}
                  </Typography>
                  <Typography variant="caption">{props.selectedCompany.cid}</Typography>
                  <Typography variant="body" paragraph>
                     People: {props.selectedCompany.totalPeople}
                  </Typography>
               </Box>
               <Box>
                  <Button onClick={() => runReport()}>Run Report</Button>
               </Box>
            </Box>
         </Grid>
         {userMetadata && (
            <Grid item xs={12}>
               <Table>
                  <TableHead>
                     <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Date Created</TableCell>
                        <TableCell>Last Signed In</TableCell>
                        <TableCell>Last Refreshed</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {Object.values(userMetadata).map((user) => (
                        <TableRow key={user.uid}>
                           <TableCell>{user.email}</TableCell>
                           <TableCell>{user.creationTime}</TableCell>
                           <TableCell>{user.lastSignInTime}</TableCell>
                           <TableCell>{user.lastRefreshTime}</TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </Grid>
         )}
         <Grid item xs={12}>
            <CompanyTabs company={props.selectedCompany} />
         </Grid>
      </Grid>
   );
};

export default withFirebase(CompanyOverview);
