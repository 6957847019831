import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CompanyAdmins from '../companyAdmins';
import Billing from './billing';
import CompanyLogs from './companyLogs';

const CompanyTabs = (props) => {
   const [activeTab, setActiveTab] = React.useState(0);

   const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
   };

   return (
      <div>
         <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="tabs"
         >
            <Tab label="Billing" />
            <Tab label="Settings" />
            <Tab label="Logs" />
         </Tabs>
         {/* Render different content based on the active tab */}
         {activeTab === 0 && (
            <div>
               <Billing company={props.company} />
            </div>
         )}
         {activeTab === 1 && (
            <div>
               <CompanyAdmins company={props.company} />
            </div>
         )}
         {activeTab === 2 && (
            <div>
               <CompanyLogs company={props.company} />
            </div>
         )}
      </div>
   );
};

export default CompanyTabs;
