import React, { useEffect } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { withFirebase } from '../../../Firebase';
import { set } from 'date-fns';

const LocationUsage = (props) => {
   //get the location usage data from props.firebase.locationScheduleMultiDay
   useEffect(() => {
      if (props.usageDataSet && props.location && props.weekDays) {
         const fetchData = async () => {
            try {
               const results = await props.firebase
                  .locationScheduleMultiDay(
                     props.location.lid,
                     props.weekDays[1].ref,
                     props.weekDays[0].ref,
                  )
                  .get();

               if (!results.empty) {
                  results.forEach((result) => {
                     props.setUsageData((prevState) => {
                        return {
                           ...prevState,
                           [result.id]:
                              prevState[result.id] + Object.keys(result.data()).length,
                           [props.location.lid]: {
                              ...prevState[props.location.lid],
                              [result.id]: Object.keys(result.data()).length,
                           },
                        };
                     });
                  });
               }
            } catch (error) {
               console.error('Error fetching location usage:', error);
            }
         };

         fetchData();

         return () => {
            props.setUsageData(false);
         };
      }
   }, [props.usageDataSet, props.location, props.weekDays]);

   return (
      <TableRow>
         <TableCell></TableCell>
         <TableCell>{props.location.name}</TableCell>
         <TableCell>{props.location.people}</TableCell>
         {props.usageData[props.location.lid] &&
            props.weekDays.map((day) => {
               return (
                  <TableCell key={day.ref}>
                     {props.usageData[props.location.lid][day.ref]}
                  </TableCell>
               );
            })}
      </TableRow>
   );
};

export default withFirebase(LocationUsage);
