import React, { useState } from 'react';
import { PageLayout } from '../../../layout';
import Dashboard from './dashboard';
import Loading from '../../../Loading';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import CompanyOverview from './company/companyOverview';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withAuthorization, withEmailVerification } from '../../../Session';
import { withFirebase } from '../../../Firebase';
import * as ROLES from '../../../../constants/roles';
import * as ROUTES from '../../../../constants/routes';

const SiteUsage = () => {
   const [getParams] = useSearchParams();
   const [pageType, setPageType] = useState(() => {
      const params = getParams();
      return params.cid ? 'company' : params.uid ? 'user' : 'dashboard';
   });

   return (
      <PageLayout>
         {pageType === 'dashboard' ? (
            <Dashboard />
         ) : pageType === 'company' ? (
            <CompanyOverview />
         ) : (
            <Loading />
         )}
      </PageLayout>
   );
};

const GlobalSiteUsagePage = compose(withRouter, withFirebase)(SiteUsage);

const condition = (authUser) => !!authUser && !!authUser.roles[ROLES.GLOBADMIN];

export default compose(
   withEmailVerification,
   withAuthorization(condition),
)(GlobalSiteUsagePage);
