import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';
import Chips from '../Schedule/Chips';
import ChipDialog from '../Schedule/chipDialog';
import { styled } from '@mui/material/styles';
import { Badge } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const ClickableChip = (props) => {
   const [chipModal, setChipModal] = useState(false);
   const [clickable, setClickable] = useState(props.clickable);
   const [bookings, setBookings] = useState(null);
   const user = JSON.parse(localStorage.getItem('authUser'));

   useEffect(() => {
      setClickable(props.clickable);
   }, [props.value]);

   useEffect(() => {
      const loadBookings = async () => {
         if (props.data.space && props.data.space !== 'empty') {
            let space = [];
            let bookingExists = false;
            Object.entries(props.data.space).map(([key, value]) => {
               if (value.type == 'All day') {
                  space.push({
                     bid: key,
                     sid: value.sid,
                     name: 'this name',
                     seat: value.seat,
                  });
                  bookingExists = true;
               }
            });
            if (bookingExists) {
               setBookings(space);
            } else {
               setBookings(null);
            }
         } else {
            setBookings(null);
         }
      };
      loadBookings();
   }, [props.data]);

   const StyledBadge = styled(Badge)(({ theme }) => ({
      '& .MuiBadge-badge': {
         right: -2,
         top: 8,
         backgroundColor: `${theme.palette.background.paper}`,
         border: `2px solid ${theme.palette.background.paper}`,
         padding: '4px 0px',
      },
   }));

   async function handleClick(value, ref) {
      const date = props.weekDates[ref].ref;
      async function getStatus(value) {
         switch (value) {
            case 'away':
               return '-';
            case 'ito':
               return 'wfh';
            case 'wfh':
               if (props.location.offsiteEnabled) {
                  return 'offsite';
               } else {
                  return 'away';
               }
            case 'offsite':
               return 'away';
            case '-':
               return 'ito';
            default:
               return '-';
         }
      }
      var me = {
         uid: user.uid,
         lid: props.row.lid,
         cid: props.row.cid,
      };
      if (props.data.offsiteLocation !== '' && props.data.offsiteLocation !== null) {
         setChipModal(true);
      } else if (props.data.space !== 'empty') {
         setChipModal(true);
      } else {
         setClickable(false);
         var oldStatus = value.set;
         var status = await getStatus(value.set);
         let routine = false;
         let oldLocation = '';
         if (props.row.status[props.dayNo].lid) {
            oldLocation = props.row.status[props.dayNo].lid;
         } else {
            oldLocation = props.row.lid;
         }
         if (props.row.status[props.dayNo].originalStatus) {
            oldStatus = props.row.status[props.dayNo].originalStatus;
         }
         let locations = {
            old: oldLocation,
            new: props.row.lid,
         };
         if (props.value.routine) {
            routine = {
               status: props.value.routine,
               overridden: true,
               dayNo: props.dayNo,
            };
            if (status === props.value.routine) {
               routine.overridden = false;
            }
         }
         await props.firebase.setStatus(me, date, status, oldStatus, locations, routine);
      }
      await props.firebase.setStatus(me, date, status, oldStatus, locations, routine);
   }

   if (clickable) {
      return (
         <React.Fragment>
            <ChipDialog
               chipModal={chipModal}
               setChipModal={setChipModal}
               data={props.data}
            />
            <div
               onClick={() => {
                  handleClick(props.value, props.dayNo);
               }}
            >
               <StyledBadge
                  invisible={!props.value.customTimes}
                  badgeContent={<AccessTimeIcon color="warning" fontSize="small" />}
               >
                  <Chips
                     setSpaceData={props.setSpaceData}
                     routineEnabled={props.routineEnabled}
                     availability={props.value.times}
                     data={props.data}
                     offsiteName={props.data.offsiteLocation}
                     setStatusDrawerOpen={props.setStatusDrawerOpen}
                     value={props.value.set}
                     clickable={clickable}
                     spacesEnabled={props.spacesEnabled}
                     spaces={props.spaces}
                     bookings={bookings}
                  />
               </StyledBadge>
            </div>
         </React.Fragment>
      );
   } else {
      return (
         <div>
            <StyledBadge
               invisible={!props.value.customTimes}
               badgeContent={<AccessTimeIcon color="warning" fontSize="small" />}
            >
               <Chips
                  setSpaceData={props.setSpaceData}
                  routineEnabled={props.routineEnabled}
                  availability={props.value.times}
                  data={props.data}
                  offsiteName={props.data.offsiteLocation}
                  setStatusDrawerOpen={props.setStatusDrawerOpen}
                  value={props.value.set}
                  bookings={bookings}
                  spaces={props.spaces}
                  clickable={false}
               />
            </StyledBadge>
         </div>
      );
   }
};

export default withFirebase(ClickableChip);
