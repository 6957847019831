import React, { useState, useEffect } from 'react';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import { useFirebase } from '../../../../Firebase';
import firebase from 'firebase/app';
import { Box } from '@mui/material';
import Loading from '../../../../Loading';
import TimeProgress from './timeProgress';
import { DateToday, getWeekDatesArray } from '../../../../../constants/functions';
import UserTable from './userTable';

const CompanyOverview = () => {
   const [getParams, setParams] = useSearchParams();
   const firebaseMain = useFirebase();
   const [cid, setCid] = useState(() => {
      const params = getParams();
      return params.cid ? params.cid : null;
   });
   const [usage, setUsage] = useState(null);
   const startDate = getWeekDatesArray(DateToday(-14))[0];
   useEffect(() => {
      if (cid) {
         firebaseMain.db
            .collection(`usageLogs/companies/company/${cid}/dates`)
            .where(firebase.firestore.FieldPath.documentId(), '>=', startDate)
            .get()

            .then((results) => {
               let data = {};
               results.forEach((doc) => {
                  data[doc.id] = doc.data();
               });
               setUsage(data);
            });
      }
   }, [cid]);
   return (
      <Box>
         {usage !== null ? (
            <Box>
               <Box>
                  <TimeProgress usage={usage} />
               </Box>
               <Box>
                  <UserTable cid={cid} usage={usage} startDate={startDate} />
               </Box>
            </Box>
         ) : (
            <Loading />
         )}
      </Box>
   );
};

export default CompanyOverview;
