import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { withFirebase } from '../Firebase';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemIcon } from '@material-ui/core';
import { Unsubscribe } from '@material-ui/icons';
import { getgroups } from 'process';
import AddIcon from '@material-ui/icons/Add';
import EditGroupModal from '../People/editGroupModal';
import PublicIcon from '@mui/icons-material/Public';

const StyledMenu = withStyles({
   paper: {
      border: '1px solid #d3d4d5',
   },
})((props) => (
   <Menu
      elevation={0}
      anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'left',
      }}
      transformOrigin={{
         vertical: 'top',
         horizontal: 'center',
      }}
      {...props}
   />
));

const useStyles = makeStyles((theme) => ({
   wrapper: {
      display: 'inline-flex',
      VerticalAlign: 'text-bottom',
      BoxSizing: 'inherit',
      textAlign: 'center',
      AlignItems: 'center',
      cursor: 'pointer',
   },
   title: {
      [theme.breakpoints.down('sm')]: {
         width: '175px',
         overflow: 'hidden',
         textOverflow: 'ellipsis',
         whiteSpace: 'nowrap',
      },
      [theme.breakpoints.down('xs')]: {
         width: '100px',
         overflow: 'hidden',
         textOverflow: 'ellipsis',
         whiteSpace: 'nowrap',
      },
   },
}));

const Filter = (props) => {
   const classes = useStyles();
   const authUser = props.user;
   const [anchorEl, setAnchorEl] = useState(null);
   const [items, setItems] = useState([{ id: 'thisid', name: 'Loading' }]);
   const [primaryLocation, setPrimaryLocation] = useState(false);
   const [editGroup, setEditGroup] = useState(false);
   const [selectedGroup, setSelectedGroup] = useState(null);
   const [newGroup, setNewGroup] = useState(false);

   useEffect(() => {
      if (props.user) {
         const unsubscribe = props.firebase
            .location(props.user.lid[0])
            .onSnapshot((result) => {
               if (result.exists) {
                  let loc = {
                     type: 'location',
                     name: result.data().name,
                     id: result.id,
                     lid: result.id,
                     cid: props.user.cid,
                  };
                  setPrimaryLocation(loc);
                  if (props.user.lastView && props.user.lastView.lid) {
                     if (
                        props.user.lastView.type === 'location' &&
                        props.user.lastView.lid === loc.lid
                     ) {
                        props.setDisplayFilter(loc);
                     } else {
                        return checkFilter(props.user, loc);
                     }
                  } else {
                     props.setDisplayFilter(loc);
                  }
               } else {
                  setPrimaryLocation(false);
               }
            });
         return () => unsubscribe();
      }
   }, [props.user]);

   const checkFilter = async (user, loc) => {
      let data = false;
      if (user.lastView.type === 'group') {
         data = await props.firebase
            .locationGroupDetails(user.lastView.lid, user.lastView.id)
            .get()
            .then((result) => {
               let group = false;
               if (result.exists) {
                  group = user.lastView;
                  group.name = result.data().name;
               }
               return group;
            })
            .catch((error) => {
               console.warn(error, 'Error - group for display filter did not exists');
            });
      } else {
         data = await props.firebase
            .location(user.lastView.id)
            .get()
            .then((result) => {
               let location = false;
               if (result.exists) {
                  location = user.lastView;
                  location.name = result.data().name;
               }
               return location;
            })
            .catch((error) => {
               console.warn(error, 'Error - group for display filter did not exists');
            });
      }
      if (data) {
         props.setDisplayFilter(data);
      } else {
         props.setDisplayFilter(loc);
      }
   };

   const handleClick = async (event) => {
      setAnchorEl(event.currentTarget);
      let lid = authUser.lid[0];
      let gids = authUser.gid;
      let menuItems = [primaryLocation];
      let groups = await getGroups(gids, lid);
      menuItems = menuItems.concat(groups);
      let publishedLocs = await getPublishedLocations(lid);
      if (publishedLocs.length !== 0) {
         menuItems = menuItems.concat(publishedLocs);
      }
      setItems(menuItems);
   };

   async function getGroups(gids, lid) {
      let groups = [];
      await props.firebase
         .locationGroupsIndex(lid)
         .get()
         .then((result) => {
            if (result.exists) {
               gids.map((gid) => {
                  if (result.data()[gid]) {
                     groups.push({
                        name: result.data()[gid].name,
                        id: gid,
                        type: 'group',
                        lid,
                        cid: authUser.cid,
                        global: result.data()[gid].global,
                        locationPublic: result.data()[gid].locationPublic,
                     });
                  }
               });
               Object.entries(result.data()).map(([key, value]) => {
                  if (value.locationPublic && (gids.length == 0 || !gids.includes(key))) {
                     groups.push({
                        name: value.name,
                        id: key,
                        type: 'group',
                        lid,
                        cid: authUser.cid,
                        global: value.global,
                        locationPublic: value.locationPublic,
                     });
                  }
               });
            } else {
               groups = [
                  { name: "You don't have any groups", id: 'empty', type: 'group' },
               ];
            }
            groups.sort(function (a, b) {
               var textA = a.name.toUpperCase();
               var textB = b.name.toUpperCase();
               return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
         })
         .catch((error) => {
            console.warn(error, 'Error getting location groups');
         });
      return groups;
   }
   async function getPublishedLocations(lid) {
      let locs = await props.firebase
         .company(authUser.cid)
         .get()
         .then((result) => {
            let data = [];
            if (result.exists) {
               Object.entries(result.data().locations).map(([key, value]) => {
                  if (key !== lid) {
                     if (
                        authUser.roles?.ADMIN === 'ADMIN' ||
                        (result.data().publishedLocations &&
                           result.data().publishedLocations[key])
                     ) {
                        if (
                           !result.data().excludedLocations ||
                           !result.data().excludedLocations[key] ||
                           !result.data().excludedLocations[key].includes(lid)
                        ) {
                           data.push({
                              id: key,
                              name: value,
                              lid: key,
                              type: 'location',
                              cid: authUser.cid,
                           });
                        }
                     }
                  }
               });
               data.sort(function (a, b) {
                  var textA = a.name.toUpperCase();
                  var textB = b.name.toUpperCase();
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
               });
            }
            return data;
         })
         .catch((error) => {
            console.warn(error, 'Error getting published locations');
         });
      return locs;
   }

   const handleClose = (event, item) => {
      if (item !== 'backdropClick') {
         props.setDisplayFilter(item);
         try {
            props.firebase.user(authUser.uid).set(
               {
                  lastView: item,
               },
               { merge: true },
            );
         } catch (error) {
            console.warn(error, 'Error setting the filter last view');
         }
      }

      setAnchorEl(null);
   };
   const handleModalOpen = () => {
      setEditGroup(true);
      setNewGroup(true);
   };

   return (
      <div>
         {console.log(props.displayFilter, 'DISPLAY FILTER')}
         {editGroup && (
            <EditGroupModal
               lid={props.displayFilter.id}
               cid={props.displayFilter.cid}
               setNewGroup={setNewGroup}
               newGroup={newGroup}
               editGroup={editGroup}
               setEditGroup={setEditGroup}
               selectedGroup={selectedGroup}
               setSelectedGroup={setSelectedGroup}
               setGroupFilter={props.setGroupFilter}
               groupFilter={props.groupFilter}
            />
         )}
         {props.displayFilter && (
            <React.Fragment>
               <div
                  onClick={handleClick}
                  className={classes.wrapper}
                  style={{
                     display: 'flex',
                     alignItems: 'center',
                     // maxWidth: { xs: '100px', sm: '125px', md: '250px' },
                  }}
               >
                  {props.displayFilter.type === 'group' && props.displayFilter.global && (
                     <PublicIcon color="primary" sx={{ mr: 1 }} />
                  )}
                  <Typography
                     variant="h5"
                     className={classes.title}
                     component="h1"
                     color="primary"
                     sx={{ fontSize: { xs: 'large', md: 'x-large' } }}
                  >
                     {props.displayFilter.name}
                  </Typography>
                  <ExpandMoreIcon
                     // sx={{
                     //    fontSize: { xs: 'normal', sm: 'large' },
                     // }}
                     color="primary"
                  />
               </div>
               <StyledMenu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                  }}
               >
                  {items.map((item) => (
                     <MenuItem
                        key={item.id}
                        // className={item.type == "group" && classes.groups}
                        sx={{ pl: item.type == 'group' ? '16px' : '8px' }}
                        onClick={(event) => handleClose(event, item)}
                     >
                        <ListItemIcon>
                           {item.type == 'group' ? (
                              <PeopleOutlineIcon />
                           ) : (
                              <BusinessIcon />
                           )}
                        </ListItemIcon>
                        <Stack direction="row" alignItems="center">
                           <>{item.name}</>
                           <>
                              {item.global && (
                                 <PublicIcon
                                    fontSize="small"
                                    sx={{ ml: 1, color: 'grey' }}
                                 />
                              )}
                           </>
                        </Stack>
                     </MenuItem>
                  ))}
                  {/* <MenuItem      
                     // selected={selectedIndex === 2}
                     onClick={handleModalOpen}
                  >
                     <ListItemIcon><AddIcon /></ListItemIcon>
                     Add Group
                  </MenuItem> */}
               </StyledMenu>
            </React.Fragment>
         )}
      </div>
   );
};

export default withFirebase(Filter);
