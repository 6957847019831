import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { withFirebase } from '../Firebase';
import SubscriptionSwitches from './subscriptionSwitches';
import PastMonths from '../GlobalReporting/pastMonths';

const useStyles = makeStyles((theme) => ({
   root: {
      marginTop: theme.spacing(2),
   },
   container: {
      padding: theme.spacing(2, 2),
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
}));

function Subscription(props) {
   const classes = useStyles();
   const user = JSON.parse(localStorage.getItem('authUser'));
   const [company, setCompany] = useState(false);

   useEffect(() => {
      const loadCompany = async () => {
         await props.firebase.company(user.cid).onSnapshot(
            (doc) => {
               setCompany({ ...doc.data(), cid: doc.id });
            },
            (err) => {
               console.log(`Encountered error: ${err}`);
            },
         );
         return;
      };
      loadCompany();
   }, [props.tab]);

   return (
      <React.Fragment>
         {company && (
            <Grid container direction="row" spacing={3} className={classes.root}>
               <Paper className={classes.container}>
                  <Grid item xs={12}>
                     <Typography component="h3" variant="h5">
                        {company.companyName}
                     </Typography>
                     <Typography variant="body1">
                        Created date -{' '}
                        {company.subscription.planStartDate.toDate().toDateString()}
                     </Typography>
                     <Typography variant="body1">
                        Subscription - {company.subscription.plan}
                     </Typography>
                     {company.subscription.plan === 'premium' && (
                        <Typography variant="body1">
                           Premium start date -{' '}
                           {company.subscription.planChangeDate.toDate().toDateString()}
                        </Typography>
                     )}
                     <SubscriptionSwitches company={company} />
                     <PastMonths company={company} />
                  </Grid>
               </Paper>
            </Grid>
         )}
      </React.Fragment>
   );
}
export default withFirebase(Subscription);
