import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useLocations } from '../../providers';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';
import AddTaskIcon from '@mui/icons-material/AddTask';

import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RestoreIcon from '@material-ui/icons/Restore';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Drawer } from '@material-ui/core';
import { withFirebase } from '../Firebase';
import firebase from 'firebase/app';
import { DateToday, getWeekDates } from '../../constants/functions';
import { Box } from '@mui/material';
import { manageMultipleGroupAllocation } from './Groups/functions';

function createData(name, email, location) {
   return { name, email, location };
}

function descendingComparator(a, b, orderBy) {
   if (b[orderBy] < a[orderBy]) {
      return -1;
   }
   if (b[orderBy] > a[orderBy]) {
      return 1;
   }
   return 0;
}

function getComparator(order, orderBy) {
   return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
   const stabilizedThis = array.map((el, index) => [el, index]);
   stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
   });
   return stabilizedThis.map((el) => el[0]);
}

const headCells = [
   { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
   { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
   { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
];

function EnhancedTableHead(props) {
   const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
   } = props;
   const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
   };

   return (
      <TableHead>
         <TableRow>
            <TableCell padding="checkbox">
               <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{ 'aria-label': 'select all people' }}
               />
            </TableCell>
            {headCells.map((headCell) => (
               <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
               >
                  <TableSortLabel
                     active={orderBy === headCell.id}
                     direction={orderBy === headCell.id ? order : 'asc'}
                     onClick={createSortHandler(headCell.id)}
                  >
                     {headCell.label}
                     {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                           {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                     ) : null}
                  </TableSortLabel>
               </TableCell>
            ))}
         </TableRow>
      </TableHead>
   );
}

EnhancedTableHead.propTypes = {
   classes: PropTypes.object.isRequired,
   numSelected: PropTypes.number.isRequired,
   onRequestSort: PropTypes.func.isRequired,
   onSelectAllClick: PropTypes.func.isRequired,
   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
   orderBy: PropTypes.string.isRequired,
   rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
   root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
   },
   highlight:
      theme.palette.type === 'light'
         ? {
              color: theme.palette.secondary.main,
              backgroundColor: lighten(theme.palette.secondary.light, 0.85),
           }
         : {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.secondary.dark,
           },
   title: {
      flex: '1 1 100%',
   },
}));

const EnhancedTableToolbar = (props) => {
   const classes = useToolbarStyles();
   const modalClasses = useStyles();
   const { numSelected } = props;

   async function createDemoStatuses() {
      const noOfWeeks = 4;
      props.selected.map(async (uid) => {
         for (var i = 0; i <= noOfWeeks; i++) {
            var index = 0 - i * 7;
            let date = DateToday(index);
            const weekDates = getWeekDates(date);
            await props.firebase.loadDemoWeek(weekDates, props.companyPeople[uid]);
         }
      });
   }

   return (
      <React.Fragment>
         <Modal
            open={props.bulkActionModal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={modalClasses.modal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
               timeout: 500,
            }}
         >
            <Paper className={modalClasses.modalPaper} elevation={0}>
               <WarningIcon color="secondary" />
               <Typography paragraph variant="body1">
                  You are about to {props.bulkAction} {props.selected.length} users. This
                  operation cannot be undone and all of their data will be lost and past
                  reporting will be affected.
               </Typography>
               <Typography paragraph variant="body2">
                  If you want to keep their data for reporting on how people use your
                  office, but remove them from the platform you should Archive them.
               </Typography>
               <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                     props.setBulkAction(false);
                     props.setBulkActionModal(false);
                  }}
               >
                  Cancel
               </Button>
               <Button
                  className={modalClasses.buttonRight}
                  variant="contained"
                  color="secondary"
                  onClick={() => bulkOperation(props.bulkAction, props)}
               >
                  {props.bulkAction}
               </Button>
            </Paper>
         </Modal>
         <Toolbar
            className={clsx(classes.root, {
               [classes.highlight]: numSelected > 0,
            })}
         >
            {numSelected > 0 && (
               <Typography
                  className={classes.title}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
               >
                  {numSelected} selected
               </Typography>
            )}

            {numSelected > 0 ? (
               <React.Fragment>
                  {/* <Tooltip title="Generate Demo Statuses">
          <IconButton aria-label="demoStatuses" onClick={()=>{createDemoStatuses()}}>
            <AddTaskIcon  />
          </IconButton>
        </Tooltip> */}
                  <Tooltip title="Reset Schedule">
                     <IconButton
                        aria-label="resetSchedule"
                        onClick={() => {
                           props.setBulkActionModal(true);
                           props.setBulkAction('reset');
                        }}
                     >
                        <RestoreIcon />
                     </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                     <IconButton
                        aria-label="delete"
                        onClick={() => {
                           props.setBulkActionModal(true);
                           props.setBulkAction('delete');
                        }}
                     >
                        <DeleteForeverIcon />
                     </IconButton>
                  </Tooltip>
               </React.Fragment>
            ) : (
               <Tooltip title="Filter list">
                  <IconButton aria-label="filter list">
                     <FilterListIcon />
                  </IconButton>
               </Tooltip>
            )}
         </Toolbar>
      </React.Fragment>
   );
};

async function bulkOperation(action, props) {
   const me = JSON.parse(localStorage.getItem('authUser'));
   props.setBulkActionModal(false);
   let deletions = [];
   let deleteAuthentication = [];
   let hasSchedule = [];
   props.setProcessing(true);
   if (action === 'delete') {
      let groups = false;
      if (props.company?.features?.groups) {
         groups = true;
      }
      let visitorsEnabled = false;
      if (props.company?.features?.visitors) {
         visitorsEnabled = true;
      }
      let routineEnabled = false;
      if (props.company?.features?.advancedScheduling) {
         routineEnabled = true;
      }
      const index = props.selected.indexOf(me.uid);
      if (index > -1) {
         props.selected.splice(index, 1);
      }
      props.setProcessingState({
         action,
         total: props.selected.length,
         processed: 0,
         state: 'Checking for Schedules',
      });
      var num = 0;
      for await (let person of props.selected) {
         var schedule = await props.firebase.personScheduleExists(me.cid, person).get();
         if (schedule.empty) {
            let grps = [];
            Object.keys(props.companyPeople[person].groups).map((gidKey) => {
               grps.push(gidKey);
            });
            if (
               props.companyPeople[person].status.statusId === 'active' ||
               props.companyPeople[person].status.statusId === 'invited'
            ) {
               deleteAuthentication.push({
                  uid: person,
                  email: props.companyPeople[person].email.toLowerCase(),
                  grps,
                  name: props.companyPeople[person].name,
               });
            } else {
               deletions.push({
                  uid: person,
                  email: props.companyPeople[person].email.toLowerCase(),
                  grps,
                  name: props.companyPeople[person].name,
               });
            }
         } else {
            hasSchedule.push(person);
         }
         num++;
         props.setProcessingState((prevState) => ({
            ...prevState,
            processed: num,
         }));
      }
      num = 1;
      props.setProcessingState({
         action,
         total: deletions.length + deleteAuthentication.length,
         processed: 1,
         state: 'Removing Group memberships',
      });
      for await (let person of deletions) {
         try {
            await manageMultipleGroupAllocation(
               props.firebase,
               person.grps,
               person,
               person.uid,
               props.displayFilter.id,
               me.cid,
               'removed',
            );
         } catch (error) {
            console.warn(error, 'Error - setting groups for new person');
         }
         num++;
         props.setProcessingState((prevState) => ({
            ...prevState,
            processed: num,
         }));
      }
      for await (let person of deleteAuthentication) {
         try {
            await manageMultipleGroupAllocation(
               props.firebase,
               person.grps,
               person,
               person.uid,
               props.displayFilter.id,
               me.cid,
               'removed',
            );
         } catch (error) {
            console.warn(error, 'Error - setting groups for new person');
         }
         num++;
         props.setProcessingState((prevState) => ({
            ...prevState,
            processed: num,
         }));
      }
      if (deletions.length !== 0) {
         const chunkSize = 50;
         num = 1;
         props.setProcessingState({
            action,
            total: deleteAuthentication.length,
            processed: 1,
            state: 'Deleting inactive users',
         });
         for (let i = 0; i < deletions.length; i += chunkSize) {
            const chunk = deletions.slice(i, i + chunkSize);
            await props.firebase.bulkDeletePeople(
               chunk,
               me.cid,
               props.displayFilter.id,
               visitorsEnabled,
            );
         }
      }
      if (deleteAuthentication.length !== 0) {
         num = 1;
         props.setProcessingState({
            action,
            total: deleteAuthentication.length,
            processed: 1,
            state: 'Deleting active users',
         });
         let data = {
            people: deleteAuthentication,
            cid: me.cid,
            lid: props.displayFilter.id,
            visitorsEnabled,
            routineEnabled,
         };
         var processDelete = firebase.functions().httpsCallable('deleteMultipleUsers');
         await processDelete(data)
            .then(async () => {
               return;
            })
            .catch((error) => {
               props.setChecked(true);
               props.setAlertMessage({ type: 'error', message: error });
            });
      }
      props.setSelected([]);
      props.setChecked(true);
      props.setProcessing(false);
      props.setBulkAction(false);

      if (hasSchedule.length === 0) {
         props.setAlertMessage({
            type: 'success',
            message: `${
               deletions.length + deleteAuthentication.length
            } users were successfully deleted.`,
         });
      } else {
         props.setAlertMessage({
            type: 'warning',
            message: `${
               deletions.length + deleteAuthentication.length
            } users were successfully deleted. NOTE: ${
               hasSchedule.length
            } users had details in their schedules and could not be deleted. You must first reset their schedules.`,
         });
      }
   } else if (action === 'reset') {
      await resetSchedules(props.selected, me.cid, props.displayFilter.id, props);
   }
}

async function resetSchedules(people, cid, lid, props) {
   let resetPeople = {};
   for await (let person of people) {
      var schedule = await props.firebase.personScheduleExists(cid, person).get();
      if (!schedule.empty) {
         resetPeople[person] = {
            dates: {},
         };
         schedule.forEach((doc) => {
            resetPeople[person].dates[doc.id] = doc.data();
         });
      }
   }
   try {
      await props.firebase.resetPeopleLocationSchedule(resetPeople, cid);
      props.setSelected([]);
      props.setProcessing(false);
      props.setBulkAction(false);
      props.setChecked(true);
      props.setAlertMessage({
         type: 'success',
         message: `${people.length} users were successfully reset.`,
      });
   } catch (error) {
      props.setChecked(true);
      props.setAlertMessage({ type: 'error', message: error });
   }
}

EnhancedTableToolbar.propTypes = {
   numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%',
   },
   paper: {
      width: '100%',
      minWidth: 750,
      marginBottom: theme.spacing(8),
   },
   table: {
      minWidth: 750,
   },
   visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
   },
   modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   modalPaper: {
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxWidth: '50%',
   },
   modalText: {
      marginBottom: theme.spacing(2),
   },
   buttonRight: {
      float: 'right',
   },
}));

const BulkActionsDrawer = (props) => {
   const classes = useStyles();
   const [order, setOrder] = useState('asc');
   const [orderBy, setOrderBy] = useState('calories');
   const [selected, setSelected] = useState([]);
   const [processing, setProcessing] = useState(false);
   const [processingState, setProcessingState] = useState(false);
   const [bulkActionModal, setBulkActionModal] = useState(false);
   const [bulkAction, setBulkAction] = useState(false);
   const { allLocations } = useLocations();

   useEffect(() => {
      setProcessing(false);
      setSelected([]);
      setProcessingState(false);
      setBulkActionModal(false);
   }, [props.bulkDeleeteDrawer]);

   const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
   };

   const handleSelectAllClick = (event) => {
      if (event.target.checked) {
         const newSelecteds = Object.values(props.companyPeople).map((n) => n.uid);
         setSelected(newSelecteds);
         return;
      }
      setSelected([]);
   };

   const handleClick = (event, uid) => {
      const selectedIndex = selected.indexOf(uid);
      let newSelected = [];
      if (selectedIndex === -1) {
         newSelected = newSelected.concat(selected, uid);
      } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
         newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
         );
      }
      setSelected(newSelected);
   };

   const isSelected = (uid) => selected.indexOf(uid) !== -1;

   return (
      <div className={classes.root}>
         <Paper className={classes.paper} elevation={0}>
            {!processing ? (
               <React.Fragment>
                  <EnhancedTableToolbar
                     numSelected={selected.length}
                     setBulkActionModal={setBulkActionModal}
                     setBulkAction={setBulkAction}
                     bulkActionModal={bulkActionModal}
                     bulkAction={bulkAction}
                     selected={selected}
                     companyPeople={props.companyPeople}
                     setSelected={setSelected}
                     setProcessing={setProcessing}
                     setChecked={props.setChecked}
                     setAlertMessage={props.setAlertMessage}
                     firebase={props.firebase}
                     displayFilter={props.displayFilter}
                     company={props.company}
                     setProcessingState={setProcessingState}
                     processingState={processingState}
                  />

                  <TableContainer>
                     <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                     >
                        <EnhancedTableHead
                           classes={classes}
                           numSelected={selected.length}
                           order={order}
                           orderBy={orderBy}
                           onSelectAllClick={handleSelectAllClick}
                           onRequestSort={handleRequestSort}
                           rowCount={Object.values(props.companyPeople).length}
                        />
                        <TableBody>
                           {stableSort(
                              Object.values(props.companyPeople),
                              getComparator(order, orderBy),
                           ).map((row, index) => {
                              const isItemSelected = isSelected(row.uid);
                              const labelId = `enhanced-table-checkbox-${index}`;
                              return (
                                 <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row.uid)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.uid}
                                    selected={isItemSelected}
                                 >
                                    <TableCell padding="checkbox">
                                       <Checkbox
                                          checked={isItemSelected}
                                          inputProps={{ 'aria-labelledby': labelId }}
                                       />
                                    </TableCell>
                                    <TableCell
                                       component="th"
                                       id={labelId}
                                       scope="row"
                                       padding="none"
                                    >
                                       {row.name.firstName} {row.name.lastName}
                                    </TableCell>
                                    <TableCell align="right">{row.email}</TableCell>
                                    <TableCell align="right">
                                       {row.lid ? allLocations[row.lid[0]] : ''}
                                    </TableCell>
                                 </TableRow>
                              );
                           })}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </React.Fragment>
            ) : (
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     flexDirection: 'column',
                     p: 4,
                  }}
               >
                  <CircularProgress />
                  <Typography variant="body1">Processing...</Typography>
                  {processingState && (
                     <Typography variant="body1">
                        {processingState.state} {processingState.processed} of{' '}
                        {processingState.total}.
                     </Typography>
                  )}
               </Box>
            )}
         </Paper>
      </div>
   );
};

export default withFirebase(BulkActionsDrawer);
