import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { withFirebase } from '../Firebase';
import { Box } from '@mui/material';

const ChipDialog = (props) => {
   const handleClose = () => {
      props.setChipModal({ open: false });
   };
   const handleRemove = async () => {
      var type = false;
      if (props.chipModal.type == 'split') {
         type = 'split';
         await props.firebase.cancelBookedSpaceForUser(props.data, type);
      } else if (props.chipModal.type == 'wrongLocation') {
         props.setChipModal({ open: false });
         try {
            await props.firebase.resetDayForUser(props.data);
         } catch (error) {
            console.warn(error, 'Error resetting day');
         }
      } else if (props.chipModal.type == 'merge') {
         type = 'merge';
         await props.firebase.cancelBookedSpaceForUser(props.data, type);
      } else if (props.chipModal.type == 'AM' || props.chipModal.type == 'PM') {
         type = props.chipModal.type;
         await props.firebase.cancelBookedSpaceForUser(props.data, type);
      } else if (props.data.space !== 'empty') {
         try {
            await props.firebase.cancelBookedSpaceForUser(props.data, type);
         } catch (error) {
            console.warn('Error removing space booking', error);
         }
         if (props.data.offsiteLocation !== null) {
            try {
               await props.firebase.removeOffSiteLocation(props.data);
            } catch (error) {
               console.warn(
                  'Error removing offsite location after removing bookings',
                  error,
               );
            }
         }
      } else if (props.data.offsiteLocation !== null) {
         await props.firebase.removeOffSiteLocation(props.data);
      }
      props.setChipModal({ open: false });
   };

   const OffsiteBody = () => {
      return (
         <React.Fragment>
            <Typography variant="body1" gutterBottom>
               Before you can change this status you need to remove the location you
               previously named and any bookings you have made.
            </Typography>
            <Typography variant="body1">
               To continue, click on Remove and then change status.
            </Typography>
         </React.Fragment>
      );
   };
   const SpacesBody = () => {
      return (
         <React.Fragment>
            <Typography variant="body1" gutterBottom>
               Before you can change this status you need to remove all space bookings for
               this day.
            </Typography>
            <Typography variant="body1" gutterBottom>
               Once removed the spaces will be available for others to book.
            </Typography>
            <Typography variant="body1">
               To continue, click on Remove and then change status.
            </Typography>
         </React.Fragment>
      );
   };
   const WrongLocationBody = () => {
      return (
         <React.Fragment>
            <Typography variant="body1" gutterBottom>
               You set this status when you had a different primary location, which can
               not be updated.
            </Typography>
            <Typography variant="body1" gutterBottom>
               You will need to reset the status before any changes can be made. This will
               remove all bookings and offsite locations for this day.
            </Typography>
            <Typography variant="body1">
               To continue, click on Remove and then set your statuses again.
            </Typography>
         </React.Fragment>
      );
   };
   const SplitBody = () => {
      return (
         <React.Fragment>
            <Typography variant="body1" gutterBottom>
               Before you can split this status you need to remove any 'All day' and 'PM'
               space bookings.
            </Typography>
            <Typography variant="body1" gutterBottom>
               Once removed the spaces will be available for others to book.
            </Typography>
            <Typography variant="body1">
               To continue, click on Remove and then click on Split Day status.
            </Typography>
         </React.Fragment>
      );
   };
   const MergeBody = () => {
      return (
         <React.Fragment>
            <Typography variant="body1" gutterBottom>
               Before you can merge your statuses you need to remove all 'PM' space
               bookings for this day.
            </Typography>
            <Typography variant="body1" gutterBottom>
               Once removed the spaces will be available for others to book.
            </Typography>
            <Typography variant="body1">
               To continue, click on Remove and then click on Split Day to toggle it off.
            </Typography>
         </React.Fragment>
      );
   };
   const AMPMBody = () => {
      return (
         <React.Fragment>
            <Typography variant="body1" gutterBottom>
               Before you can change your {props.chipModal.type} status, you need to
               remove all {props.chipModal.type} space bookings.
            </Typography>
            <Typography variant="body1" gutterBottom>
               Once removed the spaces will be available for others to book.
            </Typography>
            <Typography variant="body1">
               To continue, click on Remove and then change your {props.chipModal.type}{' '}
               status.
            </Typography>
         </React.Fragment>
      );
   };

   return (
      <div>
         <Dialog
            open={props.chipModal.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">
               {props.chipModal.type == 'split'
                  ? `Split your status?`
                  : props.chipModal.type == 'wrongLocation'
                  ? `Remove day details`
                  : props.chipModal.type == 'merge'
                  ? `Merge your statuses?`
                  : `Change your status?`}
            </DialogTitle>
            <DialogContent>
               {props.chipModal.type == 'wrongLocation' ? (
                  <WrongLocationBody />
               ) : props.chipModal.type == 'split' ? (
                  <SplitBody />
               ) : props.chipModal.type == 'merge' ? (
                  <MergeBody />
               ) : props.chipModal.type == 'AM' || props.chipModal.type == 'PM' ? (
                  <AMPMBody />
               ) : props.data.offsiteLocation !== null ? (
                  <OffsiteBody />
               ) : (
                  <SpacesBody />
               )}
            </DialogContent>
            <DialogActions>
               <Box
                  sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
               >
                  <Button onClick={handleClose} color="primary">
                     Cancel
                  </Button>
                  <Button onClick={handleRemove} color="secondary" variant="contained">
                     Remove
                  </Button>
               </Box>
            </DialogActions>
         </Dialog>
      </div>
   );
};
export default withFirebase(ChipDialog);
