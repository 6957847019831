import React, { useEffect, useState } from 'react';
import {
   Box,
   Button,
   Typography,
   FormControlLabel,
   Switch,
   Tabs,
   Tab,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import { withFirebase } from '../../Firebase';
import Chips from '../Chips';
import ChipDialog from '../chipDialog';
import { DateToday } from '../../../constants/functions';

const SplitDays = (props) => {
   const theme = useTheme();
   const isXS = useMediaQuery(theme.breakpoints.only('xs'));

   let labelPlacement = isXS ? 'bottom' : 'end';

   const [chipModal, setChipModal] = useState({ open: false });
   const [toggleSwitch, setToggleSwitch] = useState(false);
   const [am, setAm] = useState('-');
   const [pm, setPm] = useState('-');
   const [data, setData] = useState(false);
   const [clickable, setClickable] = useState(false);
   const today = DateToday();
   let location = {
      id: props.displayFilter.id,
   };
   if (props.displayFilter.type === 'group') {
      location.id = props.displayFilter.lid;
   }

   useEffect(() => {
      setData(false);
      setClickable(false);
      if (props.status.set === 'split') {
         setToggleSwitch(true);
         setAm({ set: props.status.splitDay.AM });
         setPm({ set: props.status.splitDay.PM });
      } else {
         setToggleSwitch(false);
      }
      let status = {
         user: props.data.user,
         date: props.data.date,
         space: 'empty',
         offsiteLocation: null,
         status: props.status,
         lid: location.id,
      };
      if (props.status.offsiteLocation) {
         status.offsiteLocation = props.status.offsiteLocation;
      }
      if (props.status.bookingNo && props.status.bookingNo !== 0) {
         status.space = props.status.bookings;
      }
      setData(status);
      setClickable(true);
   }, [props.status, props.data]);

   async function toggleSplitDay() {
      let dbStatus = { ...props.status };
      if (props.data.date.ref <= today.ref) {
         dbStatus.routine = false;
      }
      let bookings = false;
      if (props.status.bookingNo && props.status.bookingNo !== 0) {
         Object.values(props.status.bookings).map((booking) => {
            console.log(booking, props);
            if (
               booking.type == 'All day' ||
               booking.type == 'PM' ||
               (booking.type == 'PM' && booking.lid === displayFilter.lid)
            ) {
               bookings = true;
            }
         });
      }
      if (data.status.lid && data.status.lid !== data.user.lid) {
         setChipModal({ open: true, type: 'wrongLocation' });
      } else if (props.status.set !== 'split') {
         if (bookings) {
            setChipModal({ open: true, type: 'split' });
         } else {
            try {
               await props.firebase.daySplit(props.data, dbStatus, location);
            } catch (error) {
               console.log(error, 'Error splitting the day');
            }
         }
      } else {
         if (bookings) {
            setChipModal({ open: true, type: 'merge' });
         } else {
            try {
               await props.firebase.dayMerge(props.data, dbStatus, location);
            } catch (error) {
               console.log(error, 'Error merging day');
            }
         }
      }
   }
   async function statusClick(dayTimeSelected) {
      async function getStatus(value) {
         switch (value) {
            case 'away':
               return '-';
            case 'ito':
               return 'wfh';
            case 'wfh':
               if (props.location.offsiteEnabled) {
                  return 'offsite';
               } else {
                  return 'away';
               }

            case 'offsite':
               return 'away';
            case '-':
               return 'ito';
            default:
               return '-';
         }
      }

      if (data.status.set === 'split' && props.dayTime !== dayTimeSelected) {
         props.setDayTime(dayTimeSelected);
         setClickable(true);
      } else if (data.status.lid && data.status.lid !== data.user.lid) {
         setChipModal({ open: true, type: 'wrongLocation' });
         setClickable(true);
      } else if (data.status.set === 'split') {
         let bookings = false;
         if (props.status.bookingNo && props.status.bookingNo !== 0) {
            Object.values(props.status.bookings).map((booking) => {
               if (booking.type == dayTimeSelected) {
                  bookings = true;
               }
            });
         }
         if (
            data.offsiteLocation !== '' &&
            data.offsiteLocation !== null &&
            data.offsiteLocation[dayTimeSelected] &&
            data.offsiteLocation[dayTimeSelected] !== '' &&
            Object.keys(data.offsiteLocation).length !== 0
         ) {
            setData({ ...data, dayTime: dayTimeSelected });
            setChipModal({ open: true });
            setClickable(true);
         } else if (bookings) {
            setChipModal({ open: true, type: dayTimeSelected });
            setClickable(true);
         } else {
            setClickable(false);
            let oldStatus = data.status.splitDay[dayTimeSelected];
            let newStatus = await getStatus(data.status.splitDay[dayTimeSelected]);
            try {
               await props.firebase.setHalfDay(
                  data,
                  location,
                  oldStatus,
                  newStatus,
                  dayTimeSelected,
               );
            } catch (error) {
               console.log(error, 'Error setting half day');
            }
         }
      } else if (data.status.set !== 'split') {
         if (data.offsiteLocation !== '' && data.offsiteLocation !== null) {
            setChipModal({ open: true });
            setClickable(true);
         } else if (data.space !== 'empty') {
            setChipModal({ open: true });
            setClickable(true);
         } else {
            setClickable(false);
            var oldStatus = data.status.set;
            var newStatus = await getStatus(data.status.set);
            let routine = false;
            let oldLocation = '';
            if (data.status.lid) {
               oldLocation = data.status.lid;
            } else {
               oldLocation = data.lid;
            }
            if (data.status.originalStatus) {
               oldStatus = data.status.originalStatus;
            }
            let locations = {
               old: oldLocation,
               new: location.id,
            };
            if (data.status.routine && data.date.ref > today.ref) {
               routine = {
                  status: data.status.routine,
                  overridden: true,
               };
               if (newStatus === data.status.routine) {
                  routine.overridden = false;
               }
            }
            try {
               await props.firebase.setStatus(
                  data.user,
                  data.date.ref,
                  newStatus,
                  oldStatus,
                  locations,
                  routine,
               );
            } catch (error) {
               console.log(error, 'Error changing the status');
            }
         }
      }
   }

   return (
      <React.Fragment>
         <Typography
            variant="subtitle1"
            sx={{
               mb: 1,
               display: { xs: 'flex', sm: 'none' },
            }}
         >
            Status
         </Typography>
         <Box
            display="flex"
            justifyContent="space-evenly"
            sx={{
               width: '100%',
               color: '#C8C8C8',
               alignItems: { xs: 'center', sm: 'start' },
            }}
         >
            {props.status ? (
               <React.Fragment>
                  {data && (
                     <ChipDialog
                        chipModal={chipModal}
                        setChipModal={setChipModal}
                        data={data}
                     />
                  )}
                  <Box
                     display="flex"
                     sx={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        mt: { xs: '4px', sm: 0 },
                     }}
                  >
                     {props.status.set !== 'split' ? (
                        <Box display="flex" sx={{ justifyContent: 'center' }}>
                           {clickable ? (
                              <div
                                 onClick={(e) => {
                                    statusClick();
                                 }}
                              >
                                 <Chips
                                    value={props.status}
                                    data={props.data}
                                    offsiteName={props.status.offsiteLocation}
                                    availability={false}
                                    spaces={false}
                                    routineEnabled={false}
                                    spacesEnabled={false}
                                    clickable={clickable}
                                    timeBox={true}
                                 />
                              </div>
                           ) : (
                              <Chips
                                 value={props.status}
                                 data={props.data}
                                 offsiteName={props.status.offsiteLocation}
                                 availability={false}
                                 spaces={false}
                                 routineEnabled={false}
                                 spacesEnabled={false}
                                 clickable={clickable}
                                 timeBox={true}
                              />
                           )}
                        </Box>
                     ) : (
                        <Box display="flex" sx={{ justifyContent: 'space-between' }}>
                           <Tabs value={props.dayTime}>
                              <Tab
                                 value="AM"
                                 sx={{ pt: 0 }}
                                 label={
                                    <React.Fragment>
                                       {clickable ? (
                                          <div
                                             onClick={(e) => {
                                                statusClick('AM');
                                             }}
                                          >
                                             <Chips
                                                value={am}
                                                data={props.data}
                                                offsiteName={null}
                                                availability={false}
                                                spaces={false}
                                                routineEnabled={false}
                                                spacesEnabled={false}
                                                clickable={clickable}
                                                dayTime={'AM'}
                                                timeBox={true}
                                             />
                                          </div>
                                       ) : (
                                          <Chips
                                             value={am}
                                             data={props.data}
                                             offsiteName={null}
                                             availability={false}
                                             spaces={false}
                                             routineEnabled={false}
                                             spacesEnabled={false}
                                             clickable={clickable}
                                             dayTime={'AM'}
                                             timeBox={true}
                                          />
                                       )}
                                    </React.Fragment>
                                 }
                              />
                              <Tab
                                 value="PM"
                                 sx={{ pt: 0 }}
                                 label={
                                    <React.Fragment>
                                       {clickable ? (
                                          <div
                                             onClick={(e) => {
                                                statusClick('PM');
                                             }}
                                          >
                                             <Chips
                                                value={pm}
                                                data={props.data}
                                                offsiteName={null}
                                                availability={false}
                                                spaces={false}
                                                routineEnabled={false}
                                                spacesEnabled={false}
                                                clickable={clickable}
                                                dayTime={'PM'}
                                                timeBox={true}
                                             />
                                          </div>
                                       ) : (
                                          <Chips
                                             value={pm}
                                             data={props.data}
                                             offsiteName={null}
                                             availability={false}
                                             spaces={false}
                                             routineEnabled={false}
                                             spacesEnabled={false}
                                             clickable={clickable}
                                             dayTime={'PM'}
                                             timeBox={true}
                                          />
                                       )}
                                    </React.Fragment>
                                 }
                              />
                           </Tabs>
                        </Box>
                     )}
                     <Box>
                        {props.status.set !== 'split' ? (
                           <Typography variant="caption" sx={{ color: '#000' }}>
                              Click to change status
                           </Typography>
                        ) : (
                           <Typography variant="caption" sx={{ color: '#000' }}>
                              Click to switch time of day or status
                           </Typography>
                        )}
                     </Box>
                  </Box>
                  <Box display="flex" justifyContent="center">
                     <FormControlLabel
                        control={
                           <Switch
                              checked={toggleSwitch}
                              onChange={() => toggleSplitDay()}
                              name="split"
                           />
                        }
                        label={
                           <Typography
                              sx={{
                                 color: 'text.primary',
                                 fontSize: { xs: 'smaller', sm: 'medium' },
                              }}
                           >
                              Split Day
                           </Typography>
                        }
                        labelPlacement={labelPlacement}
                     />
                  </Box>
               </React.Fragment>
            ) : (
               <React.Fragment>Loading</React.Fragment>
            )}
         </Box>
      </React.Fragment>
   );
};
export default withFirebase(SplitDays);
