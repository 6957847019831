import React, { useEffect, useState } from 'react';
import {
   Box,
   TableCell,
   TableHead,
   TableBody,
   Table,
   TableRow,
   Button,
} from '@mui/material';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withAuthorization, withEmailVerification } from '../../../Session';
import { withFirebase } from '../../../Firebase';
import CompanyUsage from './companyUsage';
import LocationUsage from './locationUsage';
import * as ROLES from '../../../../constants/roles';
import * as ROUTES from '../../../../constants/routes';
import { DateToday, getWeekDates } from '../../../../constants/functions';
const Usage = (props) => {
   const today = DateToday(0);
   const [companies, setCompanies] = useState(false);
   const [allCompanies, setAllCompanies] = useState(false);
   const [scroll, setScroll] = useState(0);
   const [weekDays, setWeekDays] = useState(getWeekDates(today));

   //create useEffects that load all companies into an array of objects ordered by the number of people in each company. then load all locations as a child array of objects
   useEffect(() => {
      const fetchData = async () => {
         try {
            const querySnapshot = await props.firebase
               .companies()
               .where('subscription.plan', '==', 'premium')
               .get();

            const data = querySnapshot.docs.map((doc) => ({
               ...doc.data(),
               cid: doc.id,
            }));

            setCompanies(data);
         } catch (error) {
            console.error('Error fetching premium companies:', error);
         }
      };

      fetchData();

      return () => {
         // When the component unmounts, clear the companies data
         setCompanies([]);
      };
   }, []);

   useEffect(() => {
      if (companies) {
         //load all locations
         const fetchData = async () => {
            try {
               const results = await props.firebase.locations().get();

               let data = [];
               results.forEach((result) => {
                  data.push({
                     ...result.data(),
                     lid: result.id,
                  });
               });
               //add locations to companies
               let companyData = companies.map((company) => {
                  let locations = data.filter((location) => {
                     return location.cid === company.cid;
                  });
                  return {
                     ...company,
                     locations,
                  };
               });
               companyData.sort((a, b) => {
                  return b.totalPeople - a.totalPeople;
               });
               setAllCompanies(companyData);
            } catch (error) {
               console.error('Error fetching locations:', error);
            }
         };
         fetchData();
         return () => {
            setAllCompanies(false);
         };
      }
   }, [companies]);

   function scrollWeek(direction) {
      let weekDates = [];
      if (direction === -1) {
         let decrease = scroll - 7;
         weekDates = getWeekDates(DateToday(decrease));
         setScroll(decrease);
      }
      if (direction === 1) {
         let increase = scroll + 7;
         weekDates = getWeekDates(DateToday(increase));
         setScroll(increase);
      }
      setWeekDays(weekDates);
   }

   return (
      <Box sx={{ mt: 8, position: 'relative' }}>
         <Box
            sx={{
               position: 'sticky',
               top: '64px',
               backgroundColor: 'white', // Customize the background color
               zIndex: 1, // Make sure the buttons stay above the table
               display: 'flex',
               justifyContent: 'space-between',
               padding: '16px', // Customize the padding
            }}
         >
            <Button onClick={() => scrollWeek(-1)} variant="outlined">
               Previous Week
            </Button>
            <Button href={ROUTES.GLOBALREPORTING}>Back to main</Button>
            <Button onClick={() => scrollWeek(1)} variant="outlined">
               Next Week
            </Button>
         </Box>
         {allCompanies && (
            <Box sx={{ position: 'sticky', top: '75px', display: 'flex' }}>
               <Table stickyHeader>
                  <TableHead>
                     <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Number of People</TableCell>

                        {weekDays &&
                           weekDays.map((day, index) => {
                              if (index >= 1) {
                                 return (
                                    <TableCell
                                       key={index}
                                    >{`${day.day}/${day.month}/${day.year}`}</TableCell>
                                 );
                              }
                           })}
                        {weekDays && (
                           <TableCell>{`${weekDays[0].day}/${weekDays[0].month}/${weekDays[0].year}`}</TableCell>
                        )}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {allCompanies.map((company) => {
                        return (
                           <CompanyUsage
                              key={company.cid}
                              company={company}
                              weekDays={weekDays}
                           />
                        );
                     })}
                  </TableBody>
               </Table>
            </Box>
         )}
      </Box>
   );
};

const GlobalReportingUsagePage = compose(withRouter, withFirebase)(Usage);

const condition = (authUser) => !!authUser && !!authUser.roles[ROLES.GLOBADMIN];

export default compose(
   withEmailVerification,
   withAuthorization(condition),
)(GlobalReportingUsagePage);
